import React from 'react';

import { useCustomStepperStyles } from '../../styles';

import { ReactComponent as CheckMarkIcon } from 'asset/IconsPack/hx_check-circle-filled.svg';

const CompletedStep = () => {
    const classes = useCustomStepperStyles();

    return <CheckMarkIcon className={classes.completedIcon} />;
};

export default CompletedStep;
