import { makeStyles } from '@material-ui/core/styles';

export const useContinueVerificationScreenStyle = makeStyles((theme) => ({
    tabLabel: {
        minWidth: '0px',
        minHeight: '0px',
        fontFamily: theme.fontFamily,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '11.08px',
        opacity: 1,
        textTransform: 'none',
    },
    tabsWrapper: {
        marginTop: '19.39px',
    },
    tabs: {
        minHeight: '20px',
        fontFamily: theme.fontFamily,
        padding: '0px 11.08px',
    },
    indicator: {
        background: theme.primaryColor,
    },
    selected: {
        color: theme.primaryColor,
    },
    mainSection: {
        padding: '16.62px 11.08px 9.69px 11.08px',
    },
    mainSectionHeading: {
        fontFamily: theme.fontFamily,
        fontWeight: 500,
        fontSize: '11.08px',
        color: '#111827',
    },

    mainSectionSubHeading: {
        fontFamily: theme.fontFamily,
        fontSize: '9.69px',
        lineHeight: '11.49px',
        color: '#111827',
        marginTop: '10.39px',
    },

    linkText: {
        color: theme.primaryColor,
        fontWeight: 500,
        fontFamily: theme.fontFamily,
        fontSize: '9.69px',
        textAlign: 'center',
        marginTop: '18.02px',
        cursor: 'pointer',
        padding: '0 11.08px',
    },

    qrcode: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '115px',
    },

    copylink: {
        fontFamily: theme.fontFamily,
        fontWeight: '500',
        fontSize: '10px',
        textDecorationLine: 'underline',
        wordBreak: 'break-all',
        marginTop: '10.39px',
    },

    contained: {
        color: theme.primaryColor,
        width: '100%',
        padding: '10px 0px',
        marginTop: '88px',
        borderRadius: theme.button?.borderRadius,
        background: theme.primaryColor,
        '& span': {
            fontFamily: theme.fontFamily,
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '150%',
            color: '#FFFFFF',
            textTransform: 'none',
        },
    },
    outlined: {
        border: `1px solid ${theme.primaryColor}`,
        borderRadius: theme.button?.borderRadius,
        background: '#FFFFFF',
        marginTop: '88px',
        width: '100%',
        padding: '10px 0px',
        '& span': {
            fontFamily: theme.fontFamily,
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '150%',
            color: theme.primaryColor,
            textTransform: 'none',
        },
    },
    emailWrapper: {
        paddingBottom: '17px',
    },
}));
