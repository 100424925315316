import { useState } from 'react';

import { Button, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { buttonThemeSet } from 'helper/constant';
import PropTypes from 'prop-types';

import classes from './themeButton.module.css';

import { ReactComponent as CheckMark } from 'asset/IconsPack/at_check-circle-filled.svg';

const ThemeButton = ({ onChange, name, value: initialValue }) => {
    const [selectedValue, setSelectedValue] = useState(initialValue);

    const handleClick = (buttonValue) => {
        setSelectedValue(buttonValue);
        onChange?.({ name, value: buttonValue });
    };

    const isButtonSelected = (buttonValue) =>
        selectedValue.variant === buttonValue.variant &&
        selectedValue.borderRadius === buttonValue.borderRadius;

    return (
        <div className="setWrapper">
            <Grid className={classes.btnSetContainer}>
                {Object.entries(buttonThemeSet).map(([key, value], index) => (
                    <div
                        key={key + index}
                        className={clsx(
                            classes.btnBox,
                            classes[isButtonSelected(value) ? 'btnBoxSelected' : null]
                        )}
                        style={{ borderRadius: value.borderRadius }}
                    >
                        <Button
                            variant={value.variant}
                            className={clsx(classes.commonBtn, classes[value.buttonClass])}
                            style={{ borderRadius: value.borderRadius }}
                            onClick={() => handleClick(value)}
                        >
                            {isButtonSelected(value) && (
                                <CheckMark className={classes.checkMarkIcon} />
                            )}
                            Button
                        </Button>
                    </div>
                ))}
            </Grid>
        </div>
    );
};

ThemeButton.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.object,
};

export default ThemeButton;
