import { makeStyles } from '@material-ui/core';

export const useCustomStepperStyles = makeStyles((theme) => ({
    stepper: {
        backgroundColor: theme.stepperBgColor,
        padding: '8px 0px',
        width: '100%',

        '& .MuiStepper-alternativeLabel': {
            alignItems: 'center',
            width: '100%',
        },
        '& .MuiStepper-root': {
            padding: 0,
            backgroundColor: theme.stepperBgColor,
        },

        '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
            marginTop: '2px',
        },
        '& .MuiStepConnector-alternativeLabel': {
            top: '4px',
            left: 'calc(-50% + 5px)',
            right: 'calc(50% + 5px)',
        },
        '& .MuiStepConnector-line': {
            borderColor: theme.primaryColor,
        },
    },
    stepLabel: {
        marginTop: '0px',
        fontFamily: `${theme.fontFamily}`,
        fontSize: '8px',
        fontWeight: 500,
        color: '#344054',
        textAlign: 'center',
    },
    defaultStep: {
        height: '8.08px',
        width: '8.08px',
        borderRadius: '50%',
        border: `1px solid ${theme.primaryColor}`,
        backgroundColor: '#F2F4F7',
    },

    activeStep: {
        boxSizing: 'border-box',
        borderRadius: '50%',
        backgroundColor: `${theme.primaryColor}`,
        height: '11.08px',
        width: '11.08px',
        display: 'grid',
        placeItems: 'center',
        overflow: 'hidden',
        padding: '1px',

        '& span': {
            boxSizing: 'border-box',
            height: '100%',
            width: '100%',
            borderRadius: '50%',
            border: `2px solid #fff`,
        },
    },

    completedIcon: {
        width: '12.08px',
        height: '12.08px',
        color: '#039855',
    },
}));
