const { makeStyles } = require('@material-ui/core');

const useBaseStyles = makeStyles((theme) => ({
    container: {
        maxHeight: '60vh',
        overflow: 'auto',
    },
    header: {
        backgroundColor: '#fff',
        position: 'sticky',
        top: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'flex-end',
        columnGap: '10px',
        padding: '0px 24px',
        height: '56px',
        zIndex: 2,
        borderBottom: '1px solid #dddddd',
    },
    headerTitle: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#000',
        fontFamily: 'Rubik, sans-serif',
    },
    closeIcon: {
        '& path': {
            fill: '#000',
        },
    },
    listContainer: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 56px)',
    },
    listItem: {
        boxSizing: 'border-box',
        fontSize: '14px',
        fontWeight: 500,
        '&:hover': {
            backgroundColor: '#D1E9FF',
        },
    },
    listItemText: {
        padding: '0px 10px',
        '& span': {
            fontFamily: 'Rubik, sans-serif',
        },
        '&:hover': {
            color: '#1849A9',
        },
    },
}));

export default useBaseStyles;
