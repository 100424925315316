import React from 'react';

import { Stepper, Step, StepLabel, Typography } from '@material-ui/core';
import { TWO_FA_STEPS } from 'components/ThemeEditor/constants';
import PropTypes from 'prop-types';

import { useCustomStepperStyles } from './styles';

import CustomSteps from './CustomSteps';

const CustomStepper = ({ activeStep = 0 }) => {
    const classes = useCustomStepperStyles();

    return (
        <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
            <Stepper alternativeLabel activeStep={activeStep}>
                {TWO_FA_STEPS.map((label, index) => (
                    <Step key={label} className={classes.step}>
                        <StepLabel
                            StepIconComponent={CustomSteps}
                            StepIconProps={{
                                className: classes.stepLabel,
                            }}
                        >
                            <Typography className={classes.stepLabel}>{label}</Typography>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Stepper>
    );
};
CustomStepper.propTypes = {
    activeStep: PropTypes.number,
};

export default CustomStepper;
