import React from 'react';

import { Box } from '@material-ui/core';
import Input from 'components/V2/Input';
import PropTypes from 'prop-types';

import { useCommonScreesStyles } from '../MedallionPreview/styles';

const CustomInput = ({ variant }) => {
    const classes = useCommonScreesStyles();
    return (
        <Box className={classes.inputContainer}>
            <Input
                label="Email *"
                variant={variant || 'outlined'}
                customLabelClass={classes.label}
                className={classes.customInput}
                placeholder="you@example.com"
            />
        </Box>
    );
};

CustomInput.propTypes = {
    variant: PropTypes.string,
};

export default CustomInput;
