import { createApi } from '@reduxjs/toolkit/query/react';
import apiEndpoints from 'api/endpoints';
import { calculateDateRange } from 'helper/utility';
import { PAGE_SIZE } from 'helper/values';

import { baseQueryWrapper } from './baseApi';

export const globalAPI = createApi({
    reducerPath: 'global-api',
    baseQuery: baseQueryWrapper,
    endpoints: (builder) => ({
        getCountyHistory: builder.query({
            query: (userAccessCode) => ({
                url: apiEndpoints.countyHistory,
                method: 'POST',
                body: { userAccessCode },
            }),
        }),
        searchCounties: builder.query({
            query: (payload) => ({
                url: apiEndpoints.searchCounties,
                method: 'POST',
                body: payload,
            }),
        }),
        submitExtendedChecks: builder.mutation({
            query: ({ payload, checkName }) => {
                const checkUrls = {
                    'county criminal': apiEndpoints.countyCriminal,
                    'county civil': apiEndpoints.countyCivil,
                    'federal criminal': apiEndpoints.federalCriminal,
                    'federal civil': apiEndpoints.federalCivil,
                };

                return {
                    url: checkUrls[checkName],
                    method: 'POST',
                    body: payload,
                };
            },
        }),
        getCaseReport: builder.query({
            query: (payload) => ({
                url: apiEndpoints.extendedCheckCaseReport,
                method: 'POST',
                body: payload,
            }),
        }),
        getStats: builder.query({
            query: ({ duration, formattedApiDates = null } = {}) => {
                let formattedStartDate, formattedEndDate;

                if (formattedApiDates && duration === 'CUSTOM') {
                    [formattedStartDate, formattedEndDate] = formattedApiDates;
                } else {
                    const { formatStartDate, formatEndDate } = calculateDateRange(duration);
                    formattedStartDate = formatStartDate;
                    formattedEndDate = formatEndDate;
                }

                const startDate = new Date();
                formattedStartDate = formattedStartDate || startDate.toISOString();
                formattedEndDate =
                    formattedEndDate ||
                    new Date(new Date().setDate(new Date().getDate() - 7)).toISOString();

                const url = `${apiEndpoints.statistics}?start_date=${formattedStartDate}&end_date=${formattedEndDate}`;

                return {
                    url,
                    method: 'GET',
                };
            },
        }),

        getLowCodeScriptSettings: builder.query({
            query: () => ({
                url: apiEndpoints.settings.lowCodeScripts.base,
                method: 'GET',
            }),
            providesTags: (result, error, { cac }) => [{ type: 'lowCodeScriptSettings', cac }],
        }),

        manageLowCodeScriptSettings: builder.mutation({
            query: ({ payload, type }) => {
                const url = apiEndpoints.settings.lowCodeScripts?.[type];

                const data = {
                    allowedDomains: payload?.allowedDomains,
                };

                if (payload?.maxLinksPerDay) {
                    data.maxLinksPerDay = payload?.maxLinksPerDay;
                }

                return {
                    url: url,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: (result, error, { cac }) => [{ type: 'lowCodeScriptSettings', cac }],
        }),
        updateIntegrationSettings: builder.mutation({
            query: ({ key, ...rest }) => ({
                url: `${apiEndpoints.setting}/${key.id}/settings`,
                method: 'POST',
                body: rest,
            }),
        }),
        activateIntegration: builder.mutation({
            query: ({ key, ...rest }) => ({
                url: `${apiEndpoints.setting}/${key.id}/activate`,
                method: 'POST',
                body: rest,
            }),
        }),
        deactivateIntegration: builder.mutation({
            query: ({ key, ...rest }) => ({
                url: `${apiEndpoints.setting}/${key.id}/deactivate`,
                method: 'POST',
                body: rest,
            }),
        }),
        getBrandListings: builder.query({
            query: ({ brandUuid, integrationName, filter = '', page = 1, size = PAGE_SIZE }) => ({
                url: `${apiEndpoints.integrationBase}/${integrationName}/brand/${brandUuid}/listings?page=${page}&pageSize=${size}&filter=${filter}`,
                method: 'GET',
            }),
            providesTags: ['brandListings'],
        }),
        getListings: builder.query({
            query: ({ payload, page, size = PAGE_SIZE, integrationName }) => ({
                url: `${apiEndpoints.integrationBase}/${integrationName}/listings?page=${page}&pageSize=${size}`,
                method: 'POST',
                body: payload,
            }),
        }),
        moveListings: builder.mutation({
            query: ({ payload, integrationName }) => ({
                url: `${apiEndpoints.integrationBase}/${integrationName}/move/listings`,
                method: 'PATCH',
                body: payload,
            }),
            invalidatesTags: ['brandListings'],
        }),
        deleteBrand: builder.mutation({
            query: ({ brandUuid, integrationName }) => ({
                url: `${apiEndpoints.integrationBase}/${integrationName}/brand/${brandUuid}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['deleteBrand'],
        }),
        getBrand: builder.query({
            query: ({ brandUUID, integrationName }) => ({
                url: `${apiEndpoints.integrationBase}/${integrationName}/brand/${brandUUID}`,
                method: 'GET',
            }),
            providesTags: ['updateBrand'],
        }),
        getDefaultBrand: builder.query({
            query: ({ integrationName }) => ({
                url: `${apiEndpoints.integrationBase}/${integrationName}/brand`,
                method: 'GET',
            }),
        }),
        updateBrand: builder.mutation({
            query: ({ brandUUID, integrationName, payload }) => ({
                url: `${apiEndpoints.integrationBase}/${integrationName}/brand/${brandUUID}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['updateBrand'],
        }),
        listBrands: builder.query({
            query: ({ page = 1, size = PAGE_SIZE, filter = '', integrationName }) => ({
                url: `${apiEndpoints.integrationBase}/${integrationName}/brands/?page=${page}&pageSize=${size}&filter=${filter}`,
                method: 'GET',
            }),
            providesTags: ['updateBrand', 'deleteBrand'],
        }),
        markDefault: builder.mutation({
            query: ({ brandUUID, integrationName }) => ({
                url: `${apiEndpoints.integrationBase}/${integrationName}/brand/${brandUUID}/default`,
                method: 'PATCH',
            }),
            invalidatesTags: ['updateBrand'],
        }),
        fetchAllWorkflow: builder.query({
            query: ({ page = 0, size = PAGE_SIZE }) => ({
                url: `${apiEndpoints.workflowUrl}?pageNumber=${page}&pageSize=${size}`,
                method: 'GET',
            }),
            providesTags: ['workflows'],
        }),
        fetchOneWorkflow: builder.query({
            query: (workflowId) => ({
                url: `${apiEndpoints.workflowUrl}/${workflowId}`,
                method: 'GET',
            }),
            providesTags: ['workflow'],
        }),
        createBrand: builder.mutation({
            query: ({ payload, integrationName }) => ({
                url: `${apiEndpoints.integrationBase}/${integrationName}/brand`,
                method: 'POST',
                body: payload,
            }),
        }),
        getIntegrationSettings: builder.query({
            query: (id) => ({
                url: `${apiEndpoints.setting}/${id}/settings`,
                method: 'GET',
            }),
        }),
        getActivationStatus: builder.query({
            query: ({ integrationName }) => ({
                url: `${apiEndpoints.integrationBase}/${integrationName}/status`,
                method: 'GET',
            }),
        }),
        exchangeToken: builder.mutation({
            query: ({ payload, integrationName }) => ({
                url: `${apiEndpoints.integrationBase}/${integrationName}/exchange/token`,
                method: 'POST',
                body: payload,
            }),
        }),
    }),
});

export const {
    useGetCountyHistoryQuery: useCountyHistory,
    useLazySearchCountiesQuery: useSearchCounties,
    useSubmitExtendedChecksMutation: useSubmitCheck,
    useGetCaseReportQuery: useCaseReport,
    useGetStatsQuery: useStats,
    useManageLowCodeScriptSettingsMutation: useManageLowCodeScriptSettings,
    useGetLowCodeScriptSettingsQuery: useGetLowCodeScriptSettings,
    useUpdateIntegrationSettingsMutation: useUpdateIntegrationSettings,
    useActivateIntegrationMutation: useActivateIntegration,
    useDeactivateIntegrationMutation: useDeactivateIntegration,
    useExchangeTokenMutation: useExchangeToken,
    useLazyGetBrandListingsQuery,
    useGetBrandListingsQuery,
    useLazyGetListingsQuery,
    useMoveListingsMutation,
    useDeleteBrandMutation,
    useLazyGetDefaultBrandQuery,
    useGetDefaultBrandQuery,
    useLazyGetBrandQuery,
    useUpdateBrandMutation,
    useLazyListBrandsQuery,
    useMarkDefaultMutation,
    useLazyFetchAllWorkflowQuery,
    useLazyFetchOneWorkflowQuery,
    useCreateBrandMutation,
    useGetIntegrationSettingsQuery,
    useGetActivationStatusQuery,
} = globalAPI;

export default globalAPI.middleware;
