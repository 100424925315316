export const styles = (theme) => ({
    container: {},
    textField: {
        width: '100%',
        margin: '0px',
    },

    menuItem: {
        fontFamily: 'Rubik',
    },

    cssLabel: {},
    cssFilledInput: {
        '&$cssFocused': {
            border: '1px solid #0B9AD8',
            boxShadow: '0px 0px 4px rgba(27, 161, 219, 0.5)',
        },
        '&:focus': {
            border: '1px solid #0B9AD8',
            boxShadow: '0px 0px 4px rgba(27, 161, 219, 0.5)',
        },
        '&:after': {
            all: 'unset',
        },
        '&:before': {
            border: '0px',
        },
        '&:hover::before': {
            border: '0px',
        },
    },
    cssOutlinedInput: {
        '&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
            border: '1px solid #E5E7EB', //default
        },
        '&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
            border: '1px solid #1BA1DB', //hovered
        },
        '&$cssFocused $notchedOutline': {
            border: '1px solid #0B9AD8', //focused
            boxShadow: '0px 0px 4px rgba(27, 161, 219, 0.5)',
        },
        '&$error $notchedOutline': {
            border: '1px solid #EF4444', //error
            boxShadow: '0px 0px 4px rgba(239, 68, 68, 0.5)',
        },
        '&$disabled $notchedOutline': {
            background: '#F3F4F6', //disabled
            border: '1px solid #D1D5DB',
            opacity: '0.5',
        },
        '&$disabled ': {
            color: '#9CA3AF',
        },
    },
    notchedOutline: {},
    cssFocused: {},
    error: {},
    disabled: {},
    inputPlaceholder: {
        '&::placeholder': {
            fontFamily: 'Rubik',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '18px',
            lineHeight: '22px',
            color: '#6B7280',
        },
    },
    startAdornment: {
        paddingRight: '12px',
        width: '20px',
        height: '16px',
        marginLeft: '-5px',
    },
    endIcon: {
        width: '20px',
        padding: '0px',
        marginRight: '-4px',
        marginLeft: '12px',
        '&:hover, &.Mui-focusVisible': {
            borderRadius: '100%',
        },
    },
    errorMessage: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        marginTop: '6px',
    },
    errorIcon: {
        color: '#DE403C',
        fontSize: '18px',
    },
    hideErrIconOnDesktop: {
        display: 'none !important',
    },
    hideErrorIcon: {
        '@media screen and (max-width: 600px)': {
            display: 'none',
        },
    },
    helperText: {
        fontFamily: 'Rubik, sans-serif',
        color: '#DE403C',
        fontWeight: 400,
        fontSize: '14px',
    },
    select: {
        padding: '8px',
    },
    selectIconOutlined: {
        right: '13px',
    },
    asterisk: {
        marginLeft: '5px',
    },
    helperTextWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    charErr: {
        marginTop: '5px',
    },
    labelBaseClass: {
        display: 'flex',
        alignItems: 'center',
    },
    inputToolTip: {
        marginLeft: '10px',
        color: '#667085',
    },
});
