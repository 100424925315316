import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import actionConstants from 'redux/actions/actionConstant';

/**
 * This hook handles API calls with automatic loader state management.
 * Usage:
 * const apiHandler = useApiHandler();
 * const response = await apiHandler(apiCallFunction, arg1, arg2);
 */

const useApiHandler = () => {
    const dispatch = useDispatch();

    const apiHandler = useCallback(
        async (apiCall, ...args) => {
            try {
                dispatch({ type: actionConstants.START_LOADER });
                const response = await apiCall(...args);
                return response;
            } catch (error) {
                return error;
            } finally {
                dispatch({ type: actionConstants.STOP_LOADER });
            }
        },
        [dispatch]
    );

    return apiHandler;
};

export default useApiHandler;
