import React from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import Button from 'components/V2/Button';
import config from 'config';
import PropTypes from 'prop-types';

import classes from '../../../Guesty/components/ActivateGuesty/activateGuesty.module.css';
import hostfullyClasses from './activateHostfully.module.css';

import { ReactComponent as AlertIcon } from 'asset/IconsPack/at_alert-circle-filled.svg';
import { ReactComponent as HostfullyLogo } from 'asset/icons/hostfully-logo.svg';
import IntegrationLoader from 'asset/img/integration_loading.gif';

const { PROPERTY_RENTALS, APPLICATION } = config;

const HostFullyConnectionStatus = ({ clientId, isConnectionError, isExchangingToken }) => {
    const history = useHistory();

    const handleRetry = () => history.push('/integrations/hostfully');

    const renderContent = () => {
        switch (true) {
            case isConnectionError:
                return (
                    <>
                        <AlertIcon className={hostfullyClasses.alterIcon} />
                        <Typography className={hostfullyClasses.connectionError}>
                            There was an error connecting to Hostfully.
                        </Typography>
                        <Button
                            onClick={handleRetry}
                            intent="primary"
                            className={classes.primaryBtn}
                        >
                            Retry To Connect
                        </Button>
                    </>
                );
            case isExchangingToken:
                return (
                    <>
                        <img src={IntegrationLoader} alt="loading-animation" />
                        <HostfullyLogo className={hostfullyClasses.logo} />
                        <Typography className={classes.description}>
                            Please wait while we are connecting to Hostfully
                        </Typography>
                    </>
                );
            default:
                return (
                    <Box className={hostfullyClasses.container}>
                        <HostfullyLogo className={hostfullyClasses.logo} />
                        <Button
                            intent="primary"
                            href={`${PROPERTY_RENTALS.HOSTFULLY.CONNECT_URL}&clientId=${clientId}&state=authenticate&redirectUri=${APPLICATION.URL}/integrations/hostfully`}
                            className={classes.primaryBtn}
                        >
                            Connect To Hostfully
                        </Button>
                    </Box>
                );
        }
    };

    return <>{renderContent()}</>;
};

HostFullyConnectionStatus.propTypes = {
    clientId: PropTypes.string.isRequired,
    isConnectionError: PropTypes.bool,
    isExchangingToken: PropTypes.bool,
};

export default HostFullyConnectionStatus;
