import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reduxPollingMiddleware } from 'redux-polling';

import rootReducer from 'redux/reducer';
import globalApiMiddleware from 'redux/slices/globalApi';
import loginApiMiddleware from 'redux/slices/loginApi';
import { onBoardingAPI } from 'redux/slices/onBoardingApi';
import userProfileApiMiddleware from 'redux/slices/userProfileApi';
import workflowApiMiddleware from 'redux/slices/workflowApi';

const persistConfig = {
    key: 'authenticating',
    storage,
    blacklist: [
        'router',
        'REDUX_POLLING',
        'config',
        'processedData',
        'idProperties',
        'captureProperties',
        'images',
        'integration',
        'loader',
        'dashboard',
        'toaster',
        'search',
        'themeEditor',
        'eep',
    ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// determine if Redux DevTools should be enabled
const isDevEnv = process.env.REACT_APP_BUILD_ENV !== 'production';

// create store with middleware and enhancers
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat([
            reduxPollingMiddleware,
            globalApiMiddleware,
            onBoardingAPI.middleware,
            loginApiMiddleware,
            workflowApiMiddleware,
            userProfileApiMiddleware,
        ]),
    devTools: isDevEnv,
});

const persistor = persistStore(store);

// expose the store for debugging
if (process.env.NODE_ENV === 'development') {
    window.store = store;
}

const config = { store, persistor };

export default config;
