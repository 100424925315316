import React from 'react';

import styles from '../index.module.css';

import { ReactComponent as Step2 } from 'asset/CustomIcons/icon-2fa-and-risk.svg';
import { ReactComponent as Step4 } from 'asset/CustomIcons/icon-criminal-checks.svg';
import { ReactComponent as Step3 } from 'asset/CustomIcons/icon-passport-id.svg';
// Note: This will be required in the Workflow Phase - 2 👇
import { ReactComponent as Step1 } from 'asset/CustomIcons/icon-workflow-setup.svg';
import { ReactComponent as Step6 } from 'asset/IconsPack/at_dataflow-04.svg';
import { ReactComponent as Step5 } from 'asset/IconsPack/at_settings-01.svg';

export const WORKFLOW_STEPS = {
    SETUP: 'setup',
    TWO_FA_AND_RISK: 'twoFAandRisk',
    IDENTITY: 'identity',
    CRIMINAL_AND_BACKGROUND: 'criminalAndBackground',
    SETTINGS: 'settings',
    SUMMARY: 'summary',
    FINAL: 'final', // will be used in small screens
};

export const STEPPER_DATA = [
    {
        key: WORKFLOW_STEPS.SETUP,
        icon: Step1,
        name: 'Setup',
    },
    {
        key: WORKFLOW_STEPS.TWO_FA_AND_RISK,
        icon: Step2,
        name: '2FA & Risk',
    },
    {
        key: WORKFLOW_STEPS.IDENTITY,
        icon: Step3,
        name: 'Identity',
    },
    {
        key: WORKFLOW_STEPS.CRIMINAL_AND_BACKGROUND,
        icon: Step4,
        name: 'Criminal & Background',
    },
    {
        key: WORKFLOW_STEPS.SETTINGS,
        icon: Step5,
        name: 'Settings',
    },
    {
        key: WORKFLOW_STEPS.SUMMARY,
        icon: Step6,
        name: 'Summary',
    },
];

export const WORKFLOW_TOOLTIP_DATA = {
    welcome: {
        key: 'welcome',
        heading: 'Welcome to Workflow Setup!',
        description: `Follow these steps to build a customized verification process: name your workflow, add a description, and select countries. Next, configure 2FA & Risk, Identity checks, Criminal & Background screenings, and additional Settings. Finally, review everything in the Summary. Click Next to start building your workflow.`,
    },
    twoFA: {
        key: 'twoFA',
        heading: '2FA (Two-Factor Authentication)',
        description:
            'Two-factor authentication (2FA) strengthens security by requiring two forms of verification, like a one-time passcode sent to both email and phone, reducing the risk of unauthorized access.',
    },
    riskAssessment: {
        key: 'riskAssessment',
        heading: 'Risk Assessment',
        description: `Risk Assessment verifies if a user's input data aligns with our proprietary identity graph. An address is required to perform this check, and providing complete address details can help significantly reduce the risk score.`,
    },
    quickVerify: {
        key: 'quickVerify',
        heading: 'Quick Verify',
        description: `Enables eligible users to skip extra identity verification steps—like uploading document proof or answering Knowledge-Based Authentication (KBA) questions—if they successfully complete 2FA and have a low-risk score. Activating this option streamlines the verification process, making it faster and more efficient for trusted users, reducing friction while maintaining security.`,
    },
    govtId: {
        key: 'govtId',
        heading: 'Government ID',
        description: (
            <>
                Verify a Government Photo ID, read with OCR & where the barcode is decoded and
                matched with the data from the document. We also provide our degree of confidence
                that it’s authentic.{' '}
                <a
                    href="https://info.authenticate.com/government-id-verification"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.tooltipCta}
                >
                    Learn More
                </a>
            </>
        ),
    },
    selfie: {
        key: 'selfie',
        heading: 'Selfie',
        description: (
            <>
                Verify the likeness of a face in two images by comparing photos & receiving an
                instant correlation score. The comparison photo will also be measured for liveness
                using iBeta Level 2 anti-spoofing technology.{' '}
                <a
                    href="https://info.authenticate.com/facial-recognition-liveness-detection"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.tooltipCta}
                >
                    Learn More
                </a>
            </>
        ),
    },
    kba: {
        key: 'kba',
        heading: 'KBA',
        description: (
            <>
                Verify & authenticate identity with a personal knowledge quiz generated from the
                user’s name, date of birth and occasionally an address. In rare cases a partial or
                full SSN is required.{' '}
                <a
                    href="https://info.authenticate.com/knowledge-based-authentication"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.tooltipCta}
                >
                    Learn More
                </a>
            </>
        ),
    },
    faov: {
        key: 'faov',
        heading: 'Financial Account Ownership',
        description: (
            <>
                Authenticate a user’s identity by connecting their financial account, and matching
                the registered names. For higher confidence, we recommend enabling the authoritative
                database check as well.{' '}
                <a
                    href="https://info.authenticate.com/financial-account-ownership-verification-usa"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.tooltipCta}
                >
                    Learn More
                </a>
            </>
        ),
    },
    authDB: {
        key: 'authDB',
        heading: 'Authoritative Database',
        description: (
            <>
                Verify the user’s data in authoritative databases for all US states. This step is
                mandatory if Quick Verify is enabled.{' '}
                <a
                    href="https://info.authenticate.com/authoritative-database-search-usa"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.tooltipCta}
                >
                    Learn More
                </a>
            </>
        ),
    },
    criminalCheck: {
        key: 'criminalCheck',
        heading: 'Criminal Check Indicator',
        description: (
            <>
                Generate a response as a TRUE or FALSE if any records may be available for the user
                in sex offender registries or criminal records databases from all 50 US states. This
                search may include Department of Corrections, Administrative Office of the Courts &
                County Courts records. Coverage varies by state.{' '}
                <a
                    href="https://info.authenticate.com/criminal-search-usa"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.tooltipCta}
                >
                    Learn More
                </a>
            </>
        ),
    },
    sevenYearCriminalCheck: {
        key: 'sevenYearCriminalCheck',
        heading: '7 Year criminal Activity check',
        description: (
            <>
                Verify any commercially available criminal activity data that can be found
                nationwide for the last seven years. No pass through fees & an instant turnaround.{' '}
                <a
                    href="https://info.authenticate.com/7-year-criminal-report-usa"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.tooltipCta}
                >
                    Learn More
                </a>
            </>
        ),
    },
    education: {
        key: 'education',
        heading: 'Education',
        description: (
            <>
                Verify School Name & Type, Campus Name, School Town & Country, Start Date & End
                Date, Degree Title, if they are currently attending and/or if they've completed
                their degree successfully - no passthrough fees.{' '}
                <a
                    href="https://info.authenticate.com/education-verification"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.tooltipCta}
                >
                    Learn More
                </a>
            </>
        ),
    },
    employment: {
        key: 'employment',
        heading: 'Employment',
        description: (
            <>
                Verify Employer Name, Town, State & Country, Job Title, Date Employed From & To,
                Supervisor Name, if they are currently employed & what their contract type is - no
                passthrough fees. If unavailable by database the average turnaround time is 48 hours
                to get a response from the source.{' '}
                <a
                    href="https://info.authenticate.com/employment-verification"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.tooltipCta}
                >
                    Learn More
                </a>
            </>
        ),
    },
    professionalLicense: {
        key: 'professionalLicense',
        heading: 'Professional License',
        description: (
            <>
                Verify License Title, Organization & Number, Start & End Date, State & Country. If
                unavailable by database the average turnaround time is 48 hours to get a response
                from the source.{' '}
                <a
                    href="https://info.authenticate.com/professional-license-verification"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.tooltipCta}
                >
                    Learn More
                </a>
            </>
        ),
    },
    redirectURL: {
        key: 'redirectURL',
        heading: 'Redirect URL',
        description: `Please enter a valid SSL secured URL. If provided, the user will be redirected to the specified URL once the verification process is complete.`,
    },
};

export const CHECKBOX_TOOLTIP_MESSAGES = {
    DISABLED_GOVT_ID: 'Available only with Government ID check',
    ONLY_US: 'Method only available for US',
    DISABLED_KBA: 'Available only with KBA check',
};

export const VERIFICATION_ATTEMPT_COUNT = {
    DEFAULT: 2,
    MAX_ATTEMPT: 10,
    MIN_ATTEMPT: 1,
};

export const WORKFLOW_CHECKS = {
    GOVT_ID: 'govt_id',
    KBA: 'kba',
    AUTH_DB: 'authoritative_db',
    BAV: 'bank',
    CRIMINAL_CHECK: 'has_criminal_record',
    SEVEN_YEAR_CRIMINAL_CHECK: 'seven_year_criminal_report',
    PROFESSIONAL_LICENSE: 'professional_license',
    EDUCATION: 'education',
    EMPLOYMENT: 'employment',
    QUICK_VERIFY: 'quick_verify',
    '2FA': '2fa',
    RISK_SCORE: 'risk_score',
};

export const MODIFIED_WORKFLOW_CHECKS = {
    [WORKFLOW_CHECKS.GOVT_ID]: 'Government ID',
    [WORKFLOW_CHECKS.KBA]: 'KBA',
    [WORKFLOW_CHECKS.AUTH_DB]: 'Authoritative database',
    [WORKFLOW_CHECKS.CRIMINAL_CHECK]: 'Criminal check Indicator',
    [WORKFLOW_CHECKS.SEVEN_YEAR_CRIMINAL_CHECK]: '7 Year Criminal Activity Check',
    [WORKFLOW_CHECKS.BAV]: 'Financial Account Ownership',
    [WORKFLOW_CHECKS.PROFESSIONAL_LICENSE]: 'Professional License',
    [WORKFLOW_CHECKS.EDUCATION]: 'Education',
    [WORKFLOW_CHECKS.EMPLOYMENT]: 'Employment',
    GOVT_ID_WITH_SELFIE: 'Government ID with Selfie',
    [WORKFLOW_CHECKS['2FA']]: '2FA',
    [WORKFLOW_CHECKS.QUICK_VERIFY]: 'Quick Verify',
    [WORKFLOW_CHECKS.RISK_SCORE]: 'Risk Assessment',
};

export const DEFAULT_WORKFLOW_PAGE_SIZE = 50;

export const WORKFLOW_PAGE_TYPES = {
    CREATE: 'CREATE WORKFLOW',
    EDIT: 'EDIT WORKFLOW',
    VIEW: 'VIEW WORKFLOW',
};

export const COUNTRY_OPTIONS = {
    USA: {
        label: 'United States of America',
        value: 'USA',
    },
    ALL: {
        label: 'All Countries',
        value: 'all',
    },
};

export const WORKFLOW_POPUP_TYPES = {
    DISCARD: 'DISCARD',
    SAVE: 'SAVE',
    DELETE: 'DELETE',
};

export const WORKFLOW_POPUP_DATA = {
    WORKFLOW_POPUP_TITLE: {
        [WORKFLOW_POPUP_TYPES.DISCARD]: 'Discard Changes?',
        [WORKFLOW_POPUP_TYPES.SAVE]: 'Save Changes?',
    },
    WORKFLOW_POPUP_DESCRIPTION: {
        [WORKFLOW_POPUP_TYPES.DISCARD]:
            'Changes to the workflow haven’t been saved. Discard changes?',
        [WORKFLOW_POPUP_TYPES.SAVE]: 'Changes to the workflow will be saved.',
    },
    WORKFLOW_POPUP_SECONDARY_BUTTON_TEXT: {
        [WORKFLOW_POPUP_TYPES.DISCARD]: 'Keep editing',
        [WORKFLOW_POPUP_TYPES.SAVE]: 'Cancel',
    },
    WORKFLOW_POPUP_PRIMARY_BUTTON_TEXT: {
        [WORKFLOW_POPUP_TYPES.DISCARD]: 'Discard',
        [WORKFLOW_POPUP_TYPES.SAVE]: 'Save',
    },
};

export const WORKFLOW_STEP_ORDER = [
    WORKFLOW_STEPS.SETUP,
    WORKFLOW_STEPS.TWO_FA_AND_RISK,
    WORKFLOW_STEPS.IDENTITY,
    WORKFLOW_STEPS.CRIMINAL_AND_BACKGROUND,
    WORKFLOW_STEPS.SETTINGS,
    WORKFLOW_STEPS.SUMMARY,
];

export const WORKFLOW_TOASTER_MESSAGES = {
    CREATE_SUCCESS: 'Workflow created successfully.',
    EDIT_SUCCESS: 'Changes saved.',
};

export const DEFAULT_CHECK_VALUES = {
    IDENTITY: {
        kba: false,
        show_kba_result: false,
        faov: false,
        auth_db: false,
    },
    CRIMINAL: {
        criminal_check_indicator: false,
        seven_year_criminal_check: false,
    },
    BACKGROUND: {
        education: false,
        employment: false,
        professional_license: false,
    },
};
