import React from 'react';

import PropTypes from 'prop-types';

import ActiveStep from './ActiveStep';
import CompletedStep from './CompletedStep';
import DefaultStep from './DefaultStep';

const CustomSteps = ({ active, completed }) => {
    if (active) return <ActiveStep />;

    if (completed) return <CompletedStep />;

    return <DefaultStep />;
};
CustomSteps.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
};
export default CustomSteps;
