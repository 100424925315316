import React, { useState } from 'react';

import { Box, Button, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import BottomDrawer from 'components/BottomDrawer';
import CustomSelect from 'components/CustomSelect';
import PropTypes from 'prop-types';

import styles from './dropdownSelect.module.css';

import { ReactComponent as ChevronDown } from 'asset/IconsPack/at_chevron-down-filled.svg';
import { ReactComponent as ChevronUp } from 'asset/IconsPack/at_chevron-up.svg';

const DropdownSelect = ({
    options = [],
    label,
    name,
    placeholder,
    value,
    onChange,
    error,
    helperText,
    menuPropsOverride,
    customContainerStyle,
    customLabelStyle,
    customStyleClass,
}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const getLabel = () => {
        const option = options.find((option) => option?.value === value);
        return option ? option.label : undefined;
    };

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(getLabel());

    const handleDropDownSelect = (e) => {
        const { value } = e.target;
        onChange(name, value);
        setSelectedValue();
    };

    const handleDrawerOptionSelect = (data) => {
        const { label, value } = data;
        setSelectedValue(label);
        onChange(name, value);
        setIsDrawerOpen(false);
    };

    return (
        <Box className={clsx(customContainerStyle, styles.dropDownSelect)}>
            <Typography className={clsx(customLabelStyle, styles.label)}>{label}</Typography>
            {isSmallScreen ? (
                <>
                    <Button
                        className={clsx(
                            styles.dropDownButton,
                            selectedValue && styles.selectedValue
                        )}
                        endIcon={isDrawerOpen ? <ChevronUp /> : <ChevronDown />}
                        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                    >
                        {selectedValue || placeholder || 'Select'}
                    </Button>
                    <BottomDrawer
                        open={isDrawerOpen}
                        setOpen={setIsDrawerOpen}
                        listData={options}
                        onClickListItem={handleDrawerOptionSelect}
                        title={label}
                    />
                </>
            ) : (
                <CustomSelect
                    options={options}
                    name={name}
                    value={value}
                    onChange={handleDropDownSelect}
                    placeholder={placeholder || 'Select'}
                    error={error}
                    helperText={helperText}
                    menuPropsOverride={menuPropsOverride}
                    customStyleClass={customStyleClass}
                />
            )}
        </Box>
    );
};

DropdownSelect.propTypes = {
    options: PropTypes.array,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.string,
    helperText: PropTypes.string,
    menuPropsOverride: PropTypes.object,
    customContainerStyle: PropTypes.string,
    customLabelStyle: PropTypes.string,
    customStyleClass: PropTypes.string,
};

export default DropdownSelect;
