import React from 'react';

import { Box } from '@material-ui/core';
import FormFooter from 'components/FormComponents/FormFooter';
import FormHeader from 'components/FormComponents/FormHeader';
import Modal from 'components/ModalV2';
import Input from 'components/V2/Input';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

//note: we can move it to a common file later
import classes from '../../../Guesty/components/ActivateModal/activateModal.module.css';

import { hostfullyActivateFormValidationSchema as validationSchema } from '../../../../utils';

//TODO - This will be changed for hostfully

const ActivateModal = ({ open, setOpen, onClose, onSubmit }) => {
    const formik = useFormik({
        initialValues: {
            hostfully_client_id: '',
            hostfully_client_secret: '',
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            await onSubmit(values, resetForm);
        },
        enableReinitialize: true,
    });

    const handleTrimmedChange = (event) => {
        const { name, value } = event.target;
        formik.setFieldValue(name, value.trim());
    };

    const handleClose = () => {
        onClose();
        formik.resetForm();
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)} disableBackdropClick={true}>
            <form className={classes.form} onSubmit={formik.handleSubmit}>
                <FormHeader
                    title="Enter OAuth Client ID & Client Secret"
                    handleClose={handleClose}
                />
                <Box className={classes.inputWrapper}>
                    <Input
                        value={formik.values.hostfully_client_id}
                        variant="outlined"
                        label="OAuth Client ID *"
                        labelClass={classes.inputLabel}
                        type="text"
                        name="hostfully_client_id"
                        className={classes.inputField}
                        handleChange={handleTrimmedChange}
                        error={
                            formik.touched.hostfully_client_id &&
                            Boolean(formik.errors.hostfully_client_id)
                        }
                        helperText={
                            formik.touched.hostfully_client_id && formik.errors.hostfully_client_id
                        }
                    />
                </Box>
                <Box className={classes.inputWrapper}>
                    <Input
                        value={formik.values.hostfully_client_secret}
                        variant="outlined"
                        label="OAuth Client Secret *"
                        labelClass={classes.inputLabel}
                        type="text"
                        name="hostfully_client_secret"
                        className={classes.inputField}
                        handleChange={handleTrimmedChange}
                        error={
                            formik.touched.hostfully_client_secret &&
                            Boolean(formik.errors.hostfully_client_secret)
                        }
                        helperText={
                            formik.touched.hostfully_client_secret &&
                            formik.errors.hostfully_client_secret
                        }
                    />
                </Box>
                <FormFooter secondaryBtnHandler={handleClose} btnText="Submit" />
            </form>
        </Modal>
    );
};

ActivateModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
};

export default ActivateModal;
