import React from 'react';

import PropTypes from 'prop-types';

import CustomStepper from '../CustomStepper';
import LogoHeader from '../LogoHeader';

const MedallionStepperHeader = ({ themeLogo, activeStep }) => {
    return (
        <>
            <LogoHeader themeLogo={themeLogo} />
            <CustomStepper activeStep={activeStep} />
        </>
    );
};

MedallionStepperHeader.propTypes = {
    themeLogo: PropTypes.object,
    activeStep: PropTypes.number,
};

export default MedallionStepperHeader;
