import { makeStyles } from '@material-ui/core/styles';

export const useCommonScreesStyles = makeStyles((theme) => ({
    // company logo on header and screen heading
    logoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '13.82px 0',
        background: theme.navbarColor,
        borderBottom: `2.77px solid ${theme.secondaryColor}`,
    },

    headerLogo: {
        width: 'auto',
        maxHeight: '14px',
    },

    screenHeading: {
        fontWeight: '500',
        fontSize: '9.69px',
        lineHeight: '14px',
        textAlign: 'center',
        color: '#333333',
        padding: '10.43px 0px',
        background: theme.headerColor,
        fontFamily: theme.fontFamily,
        borderTopLeftRadius: theme.modalBorderRadius,
        borderTopRightRadius: theme.modalBorderRadius,
    },

    cardWrapper: {
        width: '249.27px',
        height: '553.93px',
        margin: '16px',
        background: theme.modalBackgroundColor,
        fontFamily: theme.fontFamily,
        border: '1px solid rgba(0,0,0,.125)',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, .16%)',
        borderBottomLeftRadius: theme.modalBorderRadius,
        borderBottomRightRadius: theme.modalBorderRadius,

        [theme.breakpoints.down('xs')]: {
            width: '90%',
            height: '600px',
        },
    },

    buttonContainer: {
        padding: '0px 13.84px',
        borderTop: '1px solid #dddddd',
        marginTop: '33px',
    },

    contained: {
        color: theme.primaryColor,
        width: '100%',
        padding: '6px 0px',
        borderRadius: theme.button?.borderRadius,
        background: theme.primaryColor,
        boxShadow: 'none',
        marginTop: '6.92px',
        '& span': {
            fontFamily: theme.fontFamily,
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '11.08px',
            color: '#FFFFFF',
            textTransform: 'none',
        },
        '&:hover': {
            background: theme.primaryColor,
            boxShadow: 'none',
        },
    },

    outlined: {
        border: `1px solid ${theme.primaryColor}`,
        borderRadius: theme.button?.borderRadius,
        background: '#FFFFFF',
        width: '100%',
        padding: '6px 0px',
        boxShadow: 'none',
        marginTop: '6.92px',
        '& span': {
            fontFamily: theme.fontFamily,
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '11.08px',
            color: theme.primaryColor,
            textTransform: 'none',
        },
        '&:hover': {
            background: 'none',
            boxShadow: 'none',
        },
    },
    mainContent: {
        padding: '13.84px',
    },
    desc: {
        fontFamily: `${theme.fontFamily}`,
        color: '#344054',
        fontSize: '9.69px',
        fontWeight: 400,
        lineHeight: '11.49px',
    },
    inputContainer: {
        width: '100%',
        paddingTop: '9px',
    },
    customInput: {
        '& input': {
            padding: '6.46px 9.69px',
            fontFamily: `${theme.fontFamily}`,
            fontSize: '9.69px',

            '&::placeholder': {
                fontFamily: `${theme.fontFamily}`,
                fontSize: '9.69px',
            },
        },
    },
    label: {
        fontFamily: `${theme.fontFamily}`,
        fontSize: '9.69px',
        lineHeight: '16.62px',
        marginBottom: '4px',
        fontWeight: 500,
    },
}));
