import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import Dashboard from 'layouts/V2/Dashboard';
import PropTypes from 'prop-types';

import styles from '../Guesty/guesty.module.css';

import ConfigureBrand from '../Guesty/components/ConfigureBrand';
import EditDefaultBrand from '../Guesty/components/EditDefaultBrand';
import HostfullyPage from './HostfullyPage';

function HostfullyHome(props) {
    return (
        <Grid>
            <Switch>
                <Route
                    path={`${props.match.path}`}
                    render={() => <HostfullyPage {...props} integrationName="hostfully" />}
                    exact
                />
                <Route
                    path={`${props.match.path}/brands/:uuid/edit`}
                    render={() => (
                        <Dashboard mainContainerStyles={styles.editBrandWrapper}>
                            <EditDefaultBrand {...props} integrationName="hostfully" />{' '}
                        </Dashboard>
                    )}
                    exact
                />
                <Route
                    path={`${props.match.path}/brands/:uuid`}
                    render={() => <ConfigureBrand {...props} integrationName="hostfully" />}
                    exact
                />
            </Switch>
        </Grid>
    );
}

HostfullyHome.propTypes = {
    match: PropTypes.object,
};

export default withRouter(HostfullyHome);
