import React from 'react';

import clsx from 'clsx';
import InputField from 'components/Input';
import PropTypes from 'prop-types';

import { useTextFieldStyles } from './styles';

function Input(props) {
    const classes = useTextFieldStyles();
    return (
        <InputField
            {...props}
            labelClass={clsx(classes.inputFieldLabel, props.customLabelClass)}
            className={clsx(classes.inputFieldStyles, props.className)}
            disableUnderline
        />
    );
}

Input.propTypes = {
    className: PropTypes.string,
    customLabelClass: PropTypes.string,
};

export default Input;
