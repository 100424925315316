import React from 'react';

import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useCommonScreesStyles } from '../MedallionPreview/styles';

const MedallionThemeButton = (props) => {
    const styles = useCommonScreesStyles();

    return (
        <Box className={styles.buttonContainer}>
            <Button
                variant={props.button?.variant || 'contained'}
                classes={{
                    contained: styles.contained,
                    outlined: styles.outlined,
                }}
            >
                {props?.children}
            </Button>
        </Box>
    );
};

MedallionThemeButton.propTypes = {
    button: PropTypes.object,
    children: PropTypes.node,
};

export default MedallionThemeButton;
