import { makeStyles } from '@material-ui/core/styles';

export const useVerifyIdentityStyles = makeStyles((theme) => ({
    infoBox: {
        marginTop: '19.39px',
        display: 'flex',
        flexDirection: 'column',
        gap: '12.82px',
    },

    contentWrapper: {
        padding: '0px 11.8px',
    },

    rowContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '8.92px',
    },

    rowIcon: {
        height: '16px',
        width: '16px',
        flexShrink: 0,
    },

    rowTxt: {
        fontFamily: theme.fontFamily,
        fontWeight: 'normal',
        fontSize: '10px',
        lineHeight: '11.9px',
        color: '#374151',

        '& a': {
            fontWeight: 500,
            textDecoration: 'underline',
            color: theme.primaryColor,
        },
    },

    rowCheckbox: {
        padding: '0px',
        height: '12.46px',
        width: '12.46px',

        [theme.breakpoints.down('xs')]: {
            height: '8px',
            width: '8px',
        },
    },

    checked: {
        color: theme.primaryColor,
        '& svg': {
            color: theme.primaryColor,
        },
    },

    terms: {
        padding: '30.7px 0px 0px 16px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },

    submitBtnWrapper: {
        paddingBottom: '105px',
        marginTop: '6.92px',
    },

    link: {
        color: theme.primaryColor,
        fontWeight: '500',
    },
    authenticateLogo: {
        height: '8.17px',
        width: 'auto',

        '& .authenticateLogoOutlined': {
            color: `${theme.primaryColor}`,
        },
    },
}));
