import React from 'react';
import { useParams } from 'react-router-dom';

import { Box, Typography, Link } from '@material-ui/core';
import HistoryBackArrow from 'components/HistoryBackArrow';
import PropTypes from 'prop-types';

import styles from './integrationHeader.module.css';

import { ReactComponent as BackIcon } from 'asset/IconsPack/at_arrow-narrow-left-filled.svg';
import { ReactComponent as ShareIcon } from 'asset/IconsPack/at_share-04.svg';

import { PROPERTY_RENTALS } from '../../constants';

function IntegrationHeader({ title, link }) {
    const { integrationName } = useParams();
    return (
        <Box className={styles.integrationHeader}>
            <Box className={styles.titleBox}>
                <HistoryBackArrow icon={<BackIcon className={styles.backIcon} />} />
                <Typography className={styles.integrationHeading}>{title}</Typography>
            </Box>
            {integrationName !== PROPERTY_RENTALS.HOSTFULLY ? (
                <Link
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.integrationGuideLink}
                >
                    Setup Guide <ShareIcon className={styles.shareIcon} />
                </Link>
            ) : (
                <></>
            )}
        </Box>
    );
}

IntegrationHeader.propTypes = {
    title: PropTypes.string,
    link: PropTypes.string,
};

export default IntegrationHeader;
