import React, { useEffect, useRef } from 'react';

import { Grid, Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import classes from './colorChooser.module.css';

const ColorChooser = (props) => {
    const inputRef = useRef();

    const { name, value, onChange, defaultValue, title, setIsHovered } = props;

    const handleChange = (event) => {
        setIsHovered(true);

        const { value } = event.target;
        onChange?.({ name, value });
        setIsHovered(false);
    };

    const openColorPicker = () => inputRef?.current?.click();

    const onKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ' ') openColorPicker();
    };

    useEffect(() => {
        if (!value) {
            onChange?.({ name, value: defaultValue });
        }
        // eslint-disable-next-line
    }, [value, name, defaultValue]);

    return (
        <Grid className={classes.colorChooser}>
            <Typography className={classes.title}>{title}</Typography>
            <button
                type="button"
                className={classes.colorContainer}
                onClick={openColorPicker}
                onKeyDown={onKeyDown}
            >
                <Box className={classes.colorPreviewBox} style={{ backgroundColor: value }}></Box>
                <input
                    ref={inputRef}
                    type="color"
                    className={classes.colorPickerInput}
                    value={value}
                    onChange={handleChange}
                />
                <Typography className={classes.colorCode}>{value}</Typography>
            </button>
        </Grid>
    );
};

ColorChooser.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
    title: PropTypes.string,
    setIsHovered: PropTypes.func,
};

export default ColorChooser;
