import React from 'react';
import PhoneInput from 'react-phone-input-2';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useCommonScreesStyles } from '../MedallionPreview/styles';
import { usePhoneInputStyles } from './style';

const CustomPhoneInput = ({ variant }) => {
    const classes = usePhoneInputStyles();
    const styles = useCommonScreesStyles();

    return (
        <Box className={styles.inputContainer}>
            <Typography className={styles.label}>Phone Number *</Typography>
            <PhoneInput
                country={'us'}
                type="tel"
                containerClass={classes.phoneInputContainer}
                inputClass={clsx(classes.phoneInput, variant === 'filled' && classes.filled)}
                buttonClass={classes.phoneInputButton}
                specialLabel=""
                dropdownClass={classes.countryDropDown}
            />
        </Box>
    );
};

CustomPhoneInput.propTypes = {
    variant: PropTypes.string,
};

export default CustomPhoneInput;
