import React, { useRef, useState, useEffect } from 'react';

import Tooltip from 'components/Tooltip';
import PropTypes from 'prop-types';

const OverflowingTooltipText = ({
    title,
    className,
    placement = 'bottom-end',
    maxCharCount = 150,
}) => {
    const textRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        const checkOverflow = () => {
            const element = textRef.current;
            if (element) {
                setIsOverflowing(element.scrollWidth > element.clientWidth);
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);

        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, [title]);

    return (
        <Tooltip
            title={isOverflowing || title?.length > maxCharCount ? title : ''}
            arrow
            placement={placement}
        >
            <p ref={textRef} className={className}>
                {title}
            </p>
        </Tooltip>
    );
};

OverflowingTooltipText.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    placement: PropTypes.oneOf(['bottom-end', 'bottom-start']), //add more as required
    maxCharCount: PropTypes.number,
};

export default OverflowingTooltipText;
