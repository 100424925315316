import React from 'react';

import { Box, List, Drawer, Typography, ListItemText, ListItem } from '@material-ui/core';
import PropTypes from 'prop-types';

import useBaseStyles from './styles';

import { ReactComponent as CloseIcon } from 'asset/icons/close.svg';

const BottomDrawer = ({ open, setOpen, title, listData, onClickListItem }) => {
    const classes = useBaseStyles();

    const onClose = () => {
        setOpen(false);
    };

    const onClickListItemExtended = (item) => {
        onClickListItem(item);
        onClose();
    };

    return (
        <Drawer anchor="bottom" open={open} onClose={onClose}>
            <Box className={classes.container}>
                <Box className={classes.header}>
                    <Typography className={classes.headerTitle}>{title}</Typography>
                    <CloseIcon className={classes.closeIcon} onClick={onClose} />
                </Box>
                <List className={classes.listContainer}>
                    {listData.map((item, index) => (
                        <ListItem
                            button
                            key={item.value}
                            className={classes.listItem}
                            onClick={() => onClickListItemExtended(item)}
                        >
                            <ListItemText primary={item.label} className={classes.listItemText} />
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>
    );
};

BottomDrawer.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    title: PropTypes.string,
    listData: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ).isRequired,
    onClickListItem: PropTypes.func,
};

export default BottomDrawer;
