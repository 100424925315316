import React from 'react';

import { Box } from '@material-ui/core';
import FormFooter from 'components/FormComponents/FormFooter';
import FormHeader from 'components/FormComponents/FormHeader';
import Input from 'components/Input';
import Modal from 'components/ModalV2';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import classes from './activateModal.module.css';

import { guestyActivateFormValidationSchema as validationSchema } from '../../../../utils';

const ActivateModal = ({ open, setOpen, onClose, onSubmit }) => {
    const formik = useFormik({
        initialValues: {
            token: '',
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            await onSubmit(values, resetForm);
        },
        enableReinitialize: true,
    });

    const handleClose = () => {
        onClose();
        formik.resetForm();
    };

    const handleTrimmedChange = (event) => {
        const { name, value } = event.target;
        formik.setFieldValue(name, value.trim());
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)} disableBackdropClick={true}>
            <form className={classes.form} onSubmit={formik.handleSubmit}>
                <FormHeader title="Enter Integration Token" handleClose={handleClose} />
                <Box className={classes.inputWrapper}>
                    <Input
                        value={formik.values.token}
                        variant="outlined"
                        label="Integration Token *"
                        labelClass={classes.inputLabel}
                        type="text"
                        name="token"
                        className={classes.inputField}
                        handleChange={handleTrimmedChange}
                        error={formik.touched.token && Boolean(formik.errors.token)}
                        helperText={formik.touched.token && formik.errors.token}
                    />
                </Box>
                <FormFooter secondaryBtnHandler={handleClose} btnText="Submit" />
            </form>
        </Modal>
    );
};

ActivateModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
};

export default ActivateModal;
