import { makeStyles } from '@material-ui/core';

export const useTextFieldStyles = makeStyles(
    (theme) => ({
        inputFieldLabel: {
            fontFamily: 'Rubik, sans-serif',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '24px',
            textAlign: 'left',
            color: '#1d2939',
            marginBottom: '6px',
        },

        inputFieldStyles: {
            cursor: 'pointer',
            gap: '8px',
            borderRadius: '8px',

            '& input, & textarea': {
                fontSize: '16px',
                padding: '10px 14px',
            },

            '& input, & textarea, & input::placeholder, & textarea::placeholder': {
                fontFamily: 'Rubik, sans-serif',
                fontSize: '16px',
                fontWeight: 400,

                '&::placeholder': {
                    color: '#98A2B3',
                    lineHeight: '24px',
                },
            },
        },
    }),
    { name: 'InputField' }
);
