import { createSlice } from '@reduxjs/toolkit';
import { WORKFLOW_STEPS, WORKFLOW_TOOLTIP_DATA } from 'views/V2/Workflow/constants';

const defaultStepState = {
    completed: false,
    data: {},
};

const initialState = {
    currentStep: WORKFLOW_STEPS.SETUP,
    workflowType: '',
    stepsData: {
        [WORKFLOW_STEPS.SETUP]: { ...defaultStepState },
        [WORKFLOW_STEPS.TWO_FA_AND_RISK]: { ...defaultStepState },
        [WORKFLOW_STEPS.IDENTITY]: { ...defaultStepState },
        [WORKFLOW_STEPS.CRIMINAL_AND_BACKGROUND]: { ...defaultStepState },
        [WORKFLOW_STEPS.SETTINGS]: { ...defaultStepState, isEdited: false, initialValues: {} },
        [WORKFLOW_STEPS.SUMMARY]: { ...defaultStepState },
    },
    tooltip: {
        type: WORKFLOW_TOOLTIP_DATA.welcome.key,
    },
    isSubmitting: false,
    popUp: {
        isOpen: false,
        type: '',
    },
    //to detect if workflow summary is used for the first time on the current browser
    isWorkflowSummaryUsed: false,
};

export const workflowSlice = createSlice({
    name: 'workflow',
    initialState,
    reducers: {
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        setWorkflowType: (state, action) => {
            state.workflowType = action.payload;
        },
        updateTooltip: (state, action) => {
            state.tooltip.type = action.payload;
        },
        setWorkflowStepData: (state, action) => {
            const { step, values } = action.payload;

            const workflowStep = state.stepsData[step];
            if (workflowStep) {
                state.stepsData[step] = {
                    ...workflowStep,
                    completed: true, // Mark as completed if workflowStep is present
                    data: values ? { ...workflowStep.data, ...values } : workflowStep.data,
                };
            }
        },
        resetWorkflowData: (state) => {
            return {
                ...initialState,
                isWorkflowSummaryUsed: state.isWorkflowSummaryUsed,
            };
        },
        setWorkflowPopup: (state, action) => {
            state.popUp.isOpen = action.payload;
        },
        setWorkflowPopupType: (state, action) => {
            state.popUp.type = action.payload;
        },
        updateIsWorkflowSummaryUsed: (state, action) => {
            state.isWorkflowSummaryUsed = action.payload;
        },
        setWorkflowSettingsValuesEdited: (state, action) => {
            state.stepsData[WORKFLOW_STEPS.SETTINGS].isEdited = action.payload;
        },
        setWorkflowInitialValues: (state, action) => {
            state.stepsData[WORKFLOW_STEPS.SETTINGS].initialValues = action.payload;
        },
    },
});

export const {
    setCurrentStep,
    setWorkflowType,
    updateTooltip,
    setWorkflowStepData,
    resetWorkflowData,
    setWorkflowPopup,
    setWorkflowPopupType,
    updateIsWorkflowSummaryUsed,
    setWorkflowSettingsValuesEdited,
    setWorkflowInitialValues,
} = workflowSlice.actions;

export default workflowSlice.reducer;
