import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Grid, ThemeProvider } from '@material-ui/core';
import clsx from 'clsx';
import ContinueVerificationScreen from 'components/ThemeEditor/DesktopThemeEditor/components/MedallionPreview/MedallionScreens/ContinueVerificationScreen';
import TwoFAScreen from 'components/ThemeEditor/DesktopThemeEditor/components/MedallionPreview/MedallionScreens/TwoFAScreen';
import VerificationOptionsScreen from 'components/ThemeEditor/DesktopThemeEditor/components/MedallionPreview/MedallionScreens/VerificationOptionsScreen';
import VerifyIdentityScreen from 'components/ThemeEditor/DesktopThemeEditor/components/MedallionPreview/MedallionScreens/VerifyIdentityScreen';
import useMuiTheme from 'hooks/useMuiTheme';
import PropTypes from 'prop-types';

import styles from './workflowThemePreview.module.css';

function WorkflowThemePreview({ workflowData, previewScreens = 2, className = '' }) {
    const themeData = useSelector((state) => state?.themeEditor);
    const theme = useMuiTheme(workflowData?.verify_ui_config?.theme || themeData);
    const themeLogo = workflowData?.verify_ui_config;

    const medallionPreviewScreens = [
        <VerifyIdentityScreen
            key="verify"
            button={theme.button}
            themeLogo={themeLogo}
            customScreenStyle={styles.previewScreens}
        />,
        <TwoFAScreen
            key="2fa"
            themeLogo={themeLogo}
            theme={theme}
            customScreenStyle={styles.previewScreens}
        />,
        <VerificationOptionsScreen
            key="options"
            themeLogo={themeLogo}
            theme={theme}
            customScreenStyle={styles.previewScreens}
        />,
        <ContinueVerificationScreen
            key="continue"
            theme={theme}
            button={theme.button}
            textInputVariant={theme.textInputVariant}
            themeLogo={themeLogo}
            customScreenStyle={styles.previewScreens}
        />,
    ];

    return (
        <Box className={clsx(styles.previewBox, className)}>
            <ThemeProvider theme={theme}>
                <Grid className={styles.mainSection} style={{ background: theme.overlayColor }}>
                    {medallionPreviewScreens.slice(0, previewScreens)}
                </Grid>
            </ThemeProvider>
        </Box>
    );
}

WorkflowThemePreview.propTypes = {
    previewScreens: PropTypes.number,
    workflowData: PropTypes.object,
    className: PropTypes.string,
};

export default WorkflowThemePreview;
