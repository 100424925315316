import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

// component
import { Box, Typography, Button, Modal, Grid } from '@material-ui/core';
import clsx from 'clsx';
import VerifyAccessLevel from 'components/VerifyAccessLevel';
import { adminTypes } from 'helper/constant';
import PropTypes from 'prop-types';

import { setShowModal } from 'redux/actions/settings';

import classes from './style.module.css';

function PaymentInfoModal({ paymentMethods, showModal, setShowModal, settingsValues, company }) {
    const location = useLocation();
    const history = useHistory();

    const { COMPANY_OWNER } = adminTypes;

    const platform = settingsValues?.find((setting) => setting.name === 'platform');
    const isShopify = platform && platform.value === 'SHOPIFY';

    const handleClose = () => {
        setShowModal(false);
    };

    const handleSubmitBtn = () => {
        history.push('/billing/dashboard' + location.search);
        setShowModal(false);
    };

    const accountStatus = company === 'approved' || company === 'revoked';

    // condition to check if panda doc document is signed
    const agreementSigned = company?.meta_data
        ? company?.meta_data.agreement_status === 'approved'
        : true;

    // TODO: After removing shopify from portal, this condition need to refactored
    // Show modal only if the user does not have the Shopify integration active
    const show = showModal && !isShopify && (accountStatus || agreementSigned);

    return (
        paymentMethods?.length === 0 && (
            <VerifyAccessLevel allowedAdmins={[COMPANY_OWNER]}>
                <Modal open={show} onClose={handleClose} className={classes.modal}>
                    <Grid container className={classes.paymentModalContent}>
                        <Typography variant="h4" className={classes.title}>
                            Add Payment Method
                        </Typography>
                        <Box component="p" className={classes.modalText}>
                            Please enter a payment method to activate your account. Adding a payment
                            method instantly authorizes an upfront, non-refundable payment of your
                            choosing or $100 if no amount is chosen. This is a deposit for
                            utilization credit. You draw down this credit balance over time by
                            utilizing our services. Your account auto-replenishes when your
                            remaining balance falls below $50.
                        </Box>
                        <Box component="p" className={classes.modalText}>
                            <b> All payments are non-refundable.</b> If your balance falls to $0
                            before the payment method on file can be charged, your account will be
                            suspended pending replenishment.
                        </Box>
                        <Box component="p" className={classes.modalText}>
                            Please contact{' '}
                            <a href="mailto:support@authenticate.com" className={classes.link}>
                                support@authenticate.com
                            </a>{' '}
                            to receive larger promotional credits for larger non-refundable
                            prepayments.
                        </Box>
                        <Grid container className={classes.btn_wrapper}>
                            <Button
                                className={clsx(classes.btn, classes.btn_primary)}
                                onClick={handleClose}
                            >
                                Ask me later
                            </Button>
                            <Button
                                className={clsx(classes.btn, classes.btn_secondary)}
                                onClick={handleSubmitBtn}
                            >
                                Okay
                            </Button>
                        </Grid>
                    </Grid>
                </Modal>
            </VerifyAccessLevel>
        )
    );
}

PaymentInfoModal.propTypes = {
    paymentMethods: PropTypes.array,
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    settingsValues: PropTypes.any,
    company: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        paymentMethods: state.settings.paymentMethods,
        showModal: state.settings.showModal,
        settingsValues: state.integration?.settingsValues,
        company: state.auth?.userData?.user?.company,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setShowModal: (show) => dispatch(setShowModal(show)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfoModal);
