const actionConstants = {
    CLEAR_STATE: 'CLEAR_STATE',

    STOP_LOADER: 'STOP_LOADER',
    START_LOADER: 'START_LOADER',

    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',

    VERIFY_TOKEN_REQUEST: 'VERIFY_TOKEN_REQUEST',
    VERIFY_TOKEN_SUCCESS: 'VERIFY_TOKEN_SUCCESS',
    VERIFY_TOKEN_FAILURE: 'VERIFY_TOKEN_FAILURE',

    SET_FORGET_PASSWORD_REQUEST: 'SET_FORGET_PASSWORD_REQUEST',
    SET_FORGET_PASSWORD_SUCCESS: 'SET_FORGET_PASSWORD_SUCCESS',
    SET_FORGET_PASSWORD_FAILURE: 'SET_FORGET_PASSWORD_FAILURE',

    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',

    API_COUNT_REQUEST: 'API_COUNT_REQUEST',
    API_COUNT_SUCCESS: 'API_COUNT_SUCCESS',
    API_COUNT_FAILURE: 'API_COUNT_FAILURE',
    API_CALL_TIME_CHANGED: 'API_CALL_TIME_CHANGED',

    NOTIFIED_LOW_BALANCE: 'NOTIFIED_LOW_BALANCE',
    RESET_NOTIFIED_LOW_BALANCE: 'RESET_NOTIFIED_LOW_BALANCE',

    TOTAL_USERS_REQUEST: 'TOTAL_USERS_REQUEST',
    TOTAL_USERS_SUCCESS: 'TOTAL_USERS_SUCCESS',
    TOTAL_USERS_FAILURE: 'TOTAL_USERS_FAILURE',

    SIGN_DOCUMENT_STATUS_REQUEST: 'SIGN_DOCUMENT_STATUS_REQUEST',
    SIGN_DOCUMENT_STATUS_SUCCESS: 'SIGN_DOCUMENT_STATUS_SUCCESS',
    SIGN_DOCUMENT_STATUS_FAILURE: 'SIGN_DOCUMENT_STATUS_FAILURE',

    SIGN_DOCUMENT_SESSION_REQUEST: 'SIGN_DOCUMENT_SESSION_REQUEST',
    SIGN_DOCUMENT_SESSION_SUCCESS: 'SIGN_DOCUMENT_SESSION_SUCCESS',
    SIGN_DOCUMENT_SESSION_FAILURE: 'SIGN_DOCUMENT_SESSION_FAILURE',

    SIGN_DOCUMENT_INITIATE_REQUEST: 'SIGN_DOCUMENT_INITIATE_REQUEST',
    SIGN_DOCUMENT_INITIATE_SUCCESS: 'SIGN_DOCUMENT_INITIATE_SUCCESS',
    SIGN_DOCUMENT_INITIATE_FAILURE: 'SIGN_DOCUMENT_INITIATE_FAILURE',

    DOCUMENT_SIGNED_DATE: 'DOCUMENT_SIGNED_DATE',
    CLEAR_DOCUMENT_SIGNED_DATE: 'CLEAR_DOCUMENT_SIGNED_DATE',

    EXTENDED_CHECKS_STATUS_REQUEST: 'EXTENDED_CHECKS_STATUS_REQUEST',
    EXTENDED_CHECKS_STATUS_SUCCESS: 'EXTENDED_CHECKS_STATUS_SUCCESS',
    EXTENDED_CHECKS_STATUS_FAILURE: 'EXTENDED_CHECKS_STATUS_FAILURE',

    RESET_EXTENDED_CHECKS_STATUS: 'RESET_EXTENDED_CHECKS_STATUS',

    SET_PREVIOUS_PAGINATION: 'SET_PREVIOUS_PAGINATION',
    RESET_PREVIOUS_PAGINATION: 'RESET_PREVIOUS_PAGINATION',

    ACTIVE_REQUEST: 'ACTIVE_REQUEST',
    ACTIVE_SUCCESS: 'ACTIVE_SUCCESS',
    ACTIVE_FAILURE: 'ACTIVE_FAILURE',

    SETTING_REQUEST: 'SETTING_REQUEST',
    SETTING_SUCCESS: 'SETTING_SUCCESS',
    SETTING_FAILURE: 'SETTING_FAILURE',

    SETTING_GET_REQUEST: 'SETTING_GET_REQUEST',
    SETTING_GET_SUCCESS: 'SETTING_GET_SUCCESS',
    SETTING_GET_FAILURE: 'SETTING_GET_FAILURE',

    SETTING_POST_REQUEST: 'SETTING_POST_REQUEST',
    SETTING_POST_SUCCESS: 'SETTING_POST_SUCCESS',
    SETTING_POST_FAILURE: 'SETTING_POST_FAILURE',

    USER_LOG_REQUEST: 'USER_LOG_REQUEST',
    USER_LOG_SUCCESS: 'USER_LOG_SUCCESS',
    USER_LOG_FAILURE: 'USER_LOG_FAILURE',

    PAYMENT_METHOD_REQUEST: 'PAYMENT_METHOD_REQUEST',
    PAYMENT_METHOD_SUCCESS: 'PAYMENT_METHOD_SUCCESS',
    PAYMENT_METHOD_FAILURE: 'PAYMENT_METHOD_FAILURE',

    STRIPE_CUSTOMER_REQUEST: 'STRIPE_CUSTOMER_REQUEST',
    STRIPE_CUSTOMER_SUCCESS: 'STRIPE_CUSTOMER_SUCCESS',
    STRIPE_CUSTOMER_FAILURE: 'STRIPE_CUSTOMER_FAILURE',

    SETUP_INTENT_REQUEST: 'SETUP_INTENT_REQUEST',
    SETUP_INTENT_SUCCESS: 'SETUP_INTENT_SUCCESS',
    SETUP_INTENT_FAILURE: 'SETUP_INTENT_FAILURE',

    CREATE_METHOD_REQUEST: 'CREATE_METHOD_REQUEST',
    CREATE_METHOD_SUCCESS: 'CREATE_METHOD_SUCCESS',
    CREATE_METHOD_FAILURE: 'CREATE_METHOD_FAILURE',

    SAVE_THEME_VALUES: 'SAVE_THEME_VALUES',
    CLEAR_THEME_VALUES: 'CLEAR_THEME_VALUES',
    IS_THEME_CHANGED: 'IS_THEME_CHANGED',
    RESET_THEME_AND_LOGO_VALUES: 'RESET_THEME_AND_LOGO_VALUES',
    IS_LOGO_CLEARED: 'IS_LOGO_CLEARED',

    REMOVE_CARD_REQUEST: 'REMOVE_CARD_REQUEST',
    REMOVE_CARD_SUCCESS: 'REMOVE_CARD_SUCCESS',
    REMOVE_CARD_FAILURE: 'REMOVE_CARD_FAILURE',

    SAVE_REDIRECTURL_REQUEST: 'SAVE_REDIRECTURL_REQUEST',
    SAVE_REDIRECTURL_SUCCESS: 'SAVE_REDIRECTURL_SUCCESS',
    SAVE_REDIRECTURL_FAILURE: 'SAVE_REDIRECTURL_FAILURE',

    GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
    GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',

    GET_PREV_PRODUCTS_REQUEST: 'GET_PREV_PRODUCTS_REQUEST',
    GET_PREV_PRODUCTS_SUCCESS: 'GET_PREV_PRODUCTS_SUCCESS',
    GET_PREV_PRODUCTS_FAILURE: 'GET_PREV_PRODUCTS_FAILURE',

    GET_NEXT_PRODUCTS_REQUEST: 'GET_NEXT_PRODUCTS_REQUEST',
    GET_NEXT_PRODUCTS_SUCCESS: 'GET_NEXT_PRODUCTS_SUCCESS',
    GET_NEXT_PRODUCTS_FAILURE: 'GET_NEXT_PRODUCTS_FAILURE',

    GET_ENABLED_PRODUCTS_REQUEST: 'GET_ENABLED_PRODUCTS_REQUEST',
    GET_ENABLED_PRODUCTS_SUCCESS: 'GET_ENABLED_PRODUCTS_SUCCESS',
    GET_ENABLED_PRODUCTS_FAILURE: 'GET_ENABLED_PRODUCTS_FAILURE',

    RESET_PRODUCTS_SCREEN: 'RESET_PRODUCTS_SCREEN',

    SET_ENABLED_PRODUCTS_REQUEST: 'SET_ENABLED_PRODUCTS_REQUEST',
    SET_ENABLED_PRODUCTS_SUCCESS: 'SET_ENABLED_PRODUCTS_SUCCESS',
    SET_ENABLED_PRODUCTS_FAILURE: 'SET_ENABLED_PRODUCTS_FAILURE',

    OPEN_TOASTER: 'OPEN_TOASTER',
    CLOSE_TOASTER: 'CLOSE_TOASTER',

    SET_FIELD_VALUE: 'SET_FIELD_VALUE',
    RESET_FIELD_VALUE: 'RESET_FIELD_VALUE',

    UPDATE_COMPANY_DETAILS: 'UPDATE_COMPANY_DETAILS',

    RESET_ALL_REDUCERS: 'REST_ALL_REDUCERS',

    FETCH_ADMIN_INFORMATION_REQUEST: 'FETCH_ADMIN_INFORMATION_REQUEST',
    FETCH_ADMIN_INFORMATION_SUCCESS: 'FETCH_ADMIN_INFORMATION_SUCCESS',
    FETCH_ADMIN_INFORMATION_FAILURE: 'FETCH_ADMIN_INFORMATION_FAILURE',

    RUTTER: {
        PAYMENTS: {
            STATUS: {
                METHOD: 'RUTTER_PAYMENTS_STATUS_API',
                SUCCESS: 'RUTTER_PAYMENTS_STATUS_SUCCESS',
                FAILURE: 'RUTTER_PAYMENTS_STATUS_FAILURE',
                INPROGRESS: 'RUTTER_PAYMENTS_STATUS_INPROGRESS',
            },
            CONFIRM: {
                METHOD: 'RUTTER_PAYMENTS_CONFIRM_API',
                SUCCESS: 'RUTTER_PAYMENTS_CONFIRM_SUCCESS',
                FAILURE: 'RUTTER_PAYMENTS_CONFIRM_FAILURE',
                INPROGRESS: 'RUTTER_PAYMENTS_CONFIRM_INPROGRESS',
            },
        },
    },

    AUTH: {
        COMPANY: {
            REFRESH: {
                METHOD: 'AUTH_COMPANY_REFRESH_API',
                SUCCESS: 'AUTH_COMPANY_REFRESH_SUCCESS',
                FAILURE: 'AUTH_COMPANY_REFRESH_FAILURE',
                INPROGRESS: 'AUTH_COMPANY_REFRESH_INPROGRESS',
            },
        },
        CLEAR_TOKEN: 'AUTH_CLEAR_TOKEN',
    },

    SETTINGS: {
        CUSTOM_SENDER_EMAIL: {
            FETCH: {
                METHOD: 'SETTINGS_CSE_FETCH_API',
                SUCCESS: 'SETTINGS_CSE_FETCH_SUCCESS',
                FAILURE: 'SETTINGS_CSE_FETCH_FAILURE',
                INPROGRESS: 'SETTINGS_CSE_FETCH_INPROGRESS',
            },
            UPDATE: {
                METHOD: 'SETTINGS_CSE_UPDATE_API',
                SUCCESS: 'SETTINGS_CSE_UPDATE_SUCCESS',
                FAILURE: 'SETTINGS_CSE_UPDATE_FAILURE',
                INPROGRESS: 'SETTINGS_CSE_UPDATE_INPROGRESS',
            },
            DELETE: {
                METHOD: 'SETTINGS_CSE_DELETE_API',
                SUCCESS: 'SETTINGS_CSE_DELETE_SUCCESS',
                FAILURE: 'SETTINGS_CSE_DELETE_FAILURE',
                INPROGRESS: 'SETTINGS_CSE_DELETE_IN_PROGRESS',
            },
        },
    },

    SET_WEBHOOK_URL_REQUEST: 'SET_WEBHOOK_URL_REQUEST',
    SET_WEBHOOK_URL_SUCCESS: 'SET_WEBHOOK_URL_SUCCESS',
    SET_WEBHOOK_URL_FAILURE: 'SET_WEBHOOK_URL_FAILURE',

    GET_WEBHOOK_URL_REQUEST: 'GET_WEBHOOK_URL_REQUEST',
    GET_WEBHOOK_URL_SUCCESS: 'GET_WEBHOOK_URL_SUCCESS',
    GET_WEBHOOK_URL_FAILURE: 'GET_WEBHOOK_URL_FAILURE',

    SHOW_MODAL: 'SHOW_MODAL',

    GET_ACCESS_CODE_REQUEST: 'GET_ACCESS_CODE_REQUEST',
    GET_ACCESS_CODE_SUCCESS: 'GET_ACCESS_CODE_SUCCESS',
    GET_ACCESS_CODE_FAILURE: 'GET_ACCESS_CODE_FAILURE',

    // User Profile dashboard
    GET_BEARER_TOKEN_REQUEST: 'GET_BEARER_TOKEN_REQUEST',
    GET_BEARER_TOKEN_SUCCESS: 'GET_BEARER_TOKEN_SUCCESS',
    GET_BEARER_TOKEN_FAILURE: 'GET_BEARER_TOKEN_FAILURE',
    CLEAR_BEARER_TOKEN: 'CLEAR_BEARER_TOKEN',

    GET_TEST_RESULT_REQUEST: 'GET_TEST_RESULT_REQUEST',
    GET_TEST_RESULT_SUCCESS: 'GET_TEST_RESULT_SUCCESS',
    GET_TEST_RESULT_FAILURE: 'GET_TEST_RESULT_FAILURE',
    CLEAR_TEST_RESULT: 'CLEAR_TEST_RESULT',

    SEVEN_YEAR_DATA_REQUEST: 'SEVEN_YEAR_DATA_REQUEST',
    SEVEN_YEAR_DATA_SUCCESS: 'SEVEN_YEAR_DATA_SUCCESS',
    SEVEN_YEAR_DATA_FAILURE: 'SEVEN_YEAR_DATA_FAILURE',

    GET_JWT_TOKEN_REQUEST: 'GET_JWT_TOKEN_REQUEST',
    GET_JWT_TOKEN_SUCCESS: 'GET_JWT_TOKEN_SUCCESS',
    GET_JWT_TOKEN_FAILURE: 'GET_JWT_TOKEN_FAILURE',
    CLEAR_JWT_TOKEN: 'CLEAR_JWT_TOKEN',

    GET_JWT_LATEST_REQUEST: 'GET_JWT_LATEST_REQUEST',
    GET_JWT_LATEST_SUCCESS: 'GET_JWT_LATEST_SUCCESS',
    GET_JWT_LATEST_FAILURE: 'GET_JWT_LATEST_FAILURE',

    GET_CRIMINAL_REPORT_REQUEST: 'GET_CRIMINAL_REPORT_REQUEST',
    GET_CRIMINAL_REPORT_SUCCESS: 'GET_CRIMINAL_REPORT_SUCCESS',
    GET_CRIMINAL_REPORT_FAILURE: 'GET_CRIMINAL_REPORT_FAILURE',

    FETCH_SEVEN_YEAR_DATA_REQUEST: 'FETCH_SEVEN_YEAR_DATA_REQUEST',
    FETCH_SEVEN_YEAR_DATA_SUCCESS: 'FETCH_SEVEN_YEAR_DATA_SUCCESS',
    FETCH_SEVEN_YEAR_DATA_FAILURE: 'FETCH_SEVEN_YEAR_DATA_FAILURE',
    CLEAR_SEVEN_YEAR_DATA: 'CLEAR_SEVEN_YEAR_DATA',

    GET_USER_DATA_REQUEST: 'GET_USER_DATA_REQUEST',
    GET_USER_DATA_SUCCESS: 'GET_USER_DATA_SUCCESS',
    GET_USER_DATA_FAILURE: 'GET_USER_DATA_FAILURE',
    CLEAR_USER_DATA: 'CLEAR_USER_DATA',

    USER_DATA_UPDATED: 'USER_DATA_UPDATED',

    UPDATE_USER_DATA_REQUEST: 'UPDATE_USER_DATA_REQUEST',
    UPDATE_USER_DATA_SUCCESS: 'UPDATE_USER_DATA_SUCCESS',
    UPDATE_USER_DATA_FAILURE: 'UPDATE_USER_DATA_FAILURE',

    WORKFLOW_OVERVIEW_REQUEST: 'WORKFLOW_OVERVIEW_REQUEST',
    WORKFLOW_OVERVIEW_SUCCESS: 'WORKFLOW_OVERVIEW_SUCCESS',
    WORKFLOW_OVERVIEW_FAILURE: 'WORKFLOW_OVERVIEW_FAILURE',
    CLEAR_WORKFLOW_OVERVIEW: 'CLEAR_WORKFLOW_OVERVIEW',

    RESUME_WORKFLOW_OVERVIEW_REQUEST: 'RESUME_WORKFLOW_OVERVIEW_REQUEST',
    RESUME_WORKFLOW_OVERVIEW_SUCCESS: 'RESUME_WORKFLOW_OVERVIEW_SUCCESS',
    RESUME_WORKFLOW_OVERVIEW_FAILURE: 'RESUME_WORKFLOW_OVERVIEW_FAILURE',

    CLEAR_GET_TEST_COUNT: 'CLEAR_GET_TEST_COUNT',
    INCREASE_GET_TEST_COUNT: 'INCREASE_GET_TEST_COUNT',

    CLEAR_WORKFLOW_STATUS_COUNT: 'CLEAR_WORKFLOW_STATUS_COUNT',
    INCREASE_WORKFLOW_STATUS_COUNT: 'INCREASE_WORKFLOW_STATUS_COUNT',

    CLEAR_RESUME_WORKFLOW_COUNT: 'CLEAR_RESUME_WORKFLOW_COUNT',
    INCREASE_RESUME_WORKFLOW_COUNT: 'INCREASE_RESUME_WORKFLOW_COUNT',

    GET_INVOICE_DATA_REQUEST: 'GET_INVOICE_DATA_REQUEST',
    GET_INVOICE_DATA_SUCCESS: 'GET_INVOICE_DATA_SUCCESS',
    GET_INVOICE_DATA_FAILURE: 'GET_INVOICE_DATA_FAILURE',

    GET_BILLING_DATA_REQUEST: 'GET_BILLING_DATA_REQUEST',
    GET_BILLING_DATA_SUCCESS: 'GET_BILLING_DATA_SUCCESS',
    GET_BILLING_DATA_FAILURE: 'GET_BILLING_DATA_FAILURE',

    GET_CURRENT_CREDIT_REQUEST: 'GET_CURRENT_CREDIT_REQUEST',
    GET_CURRENT_CREDIT_SUCCESS: 'GET_CURRENT_CREDIT_SUCCESS',
    GET_CURRENT_CREDIT_FAILURE: 'GET_CURRENT_CREDIT_FAILURE',

    GET_BILLING_PLANS_REQUEST: 'GET_BILLING_PLANS_REQUEST',
    GET_BILLING_PLANS_SUCCESS: 'GET_BILLING_PLANS_SUCCESS',
    GET_BILLING_PLANS_FAILURE: 'GET_BILLING_PLANS_FAILURE',

    VERIFY_UPLOAD_ID_REQUEST: 'VERIFY_UPLOAD_ID_REQUEST',
    VERIFY_UPLOAD_ID_SUCCESS: 'VERIFY_UPLOAD_ID_SUCCESS',
    VERIFY_UPLOAD_ID_FAILURE: 'VERIFY_UPLOAD_ID_FAILURE',

    VERIFY_UPLOAD_PASSPORT_REQUEST: 'VERIFY_UPLOAD_PASSPORT_REQUEST',
    VERIFY_UPLOAD_PASSPORT_SUCCESS: 'VERIFY_UPLOAD_PASSPORT_SUCCESS',
    VERIFY_UPLOAD_PASSPORT_FAILURE: 'VERIFY_UPLOAD_PASSPORT_FAILURE',

    VERIFY_BANK_ACCOUNT_OWNER_REQUEST: 'VERIFY_BANK_ACCOUNT_OWNER_REQUEST',
    VERIFY_BANK_ACCOUNT_OWNER_SUCCESS: 'VERIFY_BANK_ACCOUNT_OWNER_SUCCESS',
    VERIFY_BANK_ACCOUNT_OWNER_FAILURE: 'VERIFY_BANK_ACCOUNT_OWNER_FAILURE',

    VERIFY_SSN_REQUEST: 'VERIFY_SSN_REQUEST',
    VERIFY_SSN_SUCCESS: 'VERIFY_SSN_SUCCESS',
    VERIFY_SSN_FAILURE: 'VERIFY_SSN_FAILURE',

    // Users dashboard
    USER_FILTER_DATA_REQUEST: 'USER_FILTER_DATA_REQUEST',
    USER_FILTER_DATA_SUCCESS: 'USER_FILTER_DATA_SUCCESS',
    USER_FILTER_DATA_FAILURE: 'USER_FILTER_DATA_FAILURE',

    ADMIN_JWT_TOKEN_REQUEST: 'ADMIN_JWT_TOKEN_REQUEST',
    ADMIN_JWT_TOKEN_SUCCESS: 'ADMIN_JWT_TOKEN_SUCCESS',
    ADMIN_JWT_TOKEN_FAILURE: 'ADMIN_JWT_TOKEN_FAILURE',

    SEND_LINK_EMAIL_REQUEST: 'SEND_LINK_EMAIL_REQUEST',
    SEND_LINK_EMAIL_SUCCESS: 'SEND_LINK_EMAIL_SUCCESS',
    SEND_LINK_EMAIL_FAILURE: 'SEND_LINK_EMAIL_FAILURE',

    CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',

    CLEAR_CREATE_USER: 'CLEAR_CREATE_USER',

    CACHE_FILTER_VALUES: 'CACHE_FILTER_VALUES',
    RESET_FILTER_VALUES: 'RESET_FILTER_VALUES',

    SET_IS_EMAIL_PREVIEW_USED: 'SET_IS_EMAIL_PREVIEW_USED',

    // Workflows

    SET_SETTINGS_VALUES: 'SET_SETTINGS_VALUES',
    RESET_SETTINGS_VALUES: 'RESET_SETTINGS_VALUES',
    SET_WORKFLOW_TITLE_VALUES: 'SET_WORKFLOW_TITLE_VALUES',
    CLEAR_WORKFLOW_TITLE_VALUES: 'CLEAR_WORKFLOW_TITLE_VALUES',
    FETCH_PREVIOUS_THEME_STATE: 'FETCH_PREVIOUS_THEME_STATE',

    SET_WORKFLOW_THEME_LOGO: 'SET_WORKFLOW_THEME_LOGO',
    CLEAR_WORKFLOW_THEME_LOGO: 'CLEAR_WORKFLOW_THEME_LOGO',

    FETCH_ALL_WORKFLOWS_REQUEST: 'FETCH_ALL_WORKFLOWS_REQUEST',
    FETCH_ALL_WORKFLOWS_SUCCESS: 'FETCH_ALL_WORKFLOWS_SUCCESS',
    FETCH_ALL_WORKFLOWS_FAILURE: 'FETCH_ALL_WORKFLOWS_FAILURE',

    CREATE_WORKFLOW_REQUEST: 'CREATE_WORKFLOW_REQUEST',
    CREATE_WORKFLOW_SUCCESS: 'CREATE_WORKFLOW_SUCCESS',
    CREATE_WORKFLOW_FAILURE: 'CREATE_WORKFLOW_FAILURE',

    UPDATE_WORKFLOW_REQUEST: 'UPDATE_WORKFLOW_REQUEST',
    UPDATE_WORKFLOW_SUCCESS: 'UPDATE_WORKFLOW_SUCCESS',
    UPDATE_WORKFLOW_FAILURE: 'UPDATE_WORKFLOW_FAILURE',

    FETCH_ONE_WORKFLOW_REQUEST: 'FETCH_ONE_WORKFLOW_REQUEST',
    FETCH_ONE_WORKFLOW_SUCCESS: 'FETCH_ONE_WORKFLOW_SUCCESS',
    FETCH_ONE_WORKFLOW_FAILURE: 'FETCH_ONE_WORKFLOW_FAILURE',

    CLEAR_FETCH_ONE_WORKFLOW: 'CLEAR_FETCH_ONE_WORKFLOW',

    SET_DEFAULT_WORKFLOW_REQUEST: 'SET_DEFAULT_WORKFLOW_REQUEST',
    SET_DEFAULT_WORKFLOW_SUCCESS: 'SET_DEFAULT_WORKFLOW_SUCCESS',
    SET_DEFAULT_WORKFLOW_FAILURE: 'SET_DEFAULT_WORKFLOW_FAILURE',

    DELETE_WORKFLOW_REQUEST: 'DELETE_WORKFLOW_REQUEST',
    DELETE_WORKFLOW_SUCCESS: 'DELETE_WORKFLOW_SUCCESS',
    DELETE_WORKFLOW_FAILURE: 'DELETE_WORKFLOW_FAILURE',

    UPDATE_WORKFLOW_THEME_LOGO_REQUEST: 'UPDATE_WORKFLOW_THEME_LOGO_REQUEST',
    UPDATE_WORKFLOW_THEME_LOGO_SUCCESS: 'UPDATE_WORKFLOW_THEME_LOGO_SUCCESS',
    UPDATE_WORKFLOW_THEME_LOGO_FAILURE: 'UPDATE_WORKFLOW_THEME_LOGO_FAILURE',

    DELETE_WORKFLOW_THEME_LOGO_REQUEST: 'DELETE_WORKFLOW_THEME_LOGO_REQUEST',
    DELETE_WORKFLOW_THEME_LOGO_SUCCESS: 'DELETE_WORKFLOW_THEME_LOGO_SUCCESS',
    DELETE_WORKFLOW_THEME_LOGO_FAILURE: 'DELETE_WORKFLOW_THEME_LOGO_FAILURE',

    // Settings notification

    VERIFYUI_NOTIFICATION_REQUEST: 'VERIFYUI_NOTIFICATION_REQUEST',
    VERIFYUI_NOTIFICATION_SUCCESS: 'VERIFYUI_NOTIFICATION_SUCCESS',
    VERIFYUI_NOTIFICATION_FAILURE: 'VERIFYUI_NOTIFICATION_FAILURE',

    // True Continuous Monitoring™  V2 👇

    ADD_USER_TO_TCM_REQUEST: 'ADD_USER_TO_TCM_REQUEST',
    ADD_USER_TO_TCM_SUCCESS: 'ADD_USER_TO_TCM_SUCCESS',
    ADD_USER_TO_TCM_FAILURE: 'ADD_USER_TO_TCM_FAILURE',

    REMOVE_USER_FROM_TCM_REQUEST: 'REMOVE_USER_FROM_TCM_REQUEST',
    REMOVE_USER_FROM_TCM_SUCCESS: 'REMOVE_USER_FROM_TCM_SUCCESS',
    REMOVE_USER_FROM_TCM_FAILURE: 'REMOVE_USER_FROM_TCM_FAILURE',

    GET_TCM_USER_STATUS_REQUEST: 'GET_TCM_USER_STATUS_REQUEST',
    GET_TCM_USER_STATUS_SUCCESS: 'GET_TCM_USER_STATUS_SUCCESS',
    GET_TCM_USER_STATUS_FAILURE: 'GET_TCM_USER_STATUS_FAILURE',

    GET_TCM_USER_RECORD_REQUEST: 'GET_TCM_USER_RECORD_REQUEST',
    GET_TCM_USER_RECORD_SUCCESS: 'GET_TCM_USER_RECORD_SUCCESS',
    GET_TCM_USER_RECORD_FAILURE: 'GET_TCM_USER_RECORD_FAILURE',

    TCM_USER_SEARCH_REQUEST: 'TCM_USER_SEARCH_REQUEST',
    TCM_USER_SEARCH_SUCCESS: 'TCM_USER_SEARCH_SUCCESS',
    TCM_USER_SEARCH_FAILURE: 'TCM_USER_SEARCH_FAILURE',

    TCM_WATCHLIST_RECORDS_REQUEST: 'TCM_WATCHLIST_RECORDS_REQUEST',
    TCM_WATCHLIST_RECORDS_SUCCESS: 'TCM_WATCHLIST_RECORDS_SUCCESS',
    TCM_WATCHLIST_RECORDS_FAILURE: 'TCM_WATCHLIST_RECORDS_FAILURE',

    CLEAR_TCM_SEARCH_RECORDS: 'CLEAR_TCM_SEARCH_RECORDS',
    STORE_TCM_PAGINATION_VALUES: 'STORE_TCM_PAGINATION_VALUES',
    RESET_TCM_PAGINATION_VALUES: 'RESET_TCM_PAGINATION_VALUES',

    //RBAC

    GET_COMPANY_ADMINS_REQUEST: 'GET_COMPANY_ADMINS_REQUEST',
    GET_COMPANY_ADMINS_SUCCESS: 'GET_COMPANY_ADMINS_SUCCESS',
    GET_COMPANY_ADMINS_FAILURE: 'GET_COMPANY_ADMINS_FAILURE',

    CREATE_AN_MEMBER_REQUEST: 'CREATE_AN_MEMBER_REQUEST',
    CREATE_AN_MEMBER_SUCCESS: 'CREATE_AN_MEMBER_SUCCESS',
    CREATE_AN_MEMBER_FAILURE: 'CREATE_AN_MEMBER_FAILURE',

    UPDATE_MEMBER_REQUEST: 'UPDATE_MEMBER_REQUEST',
    UPDATE_MEMBER_SUCCESS: 'UPDATE_MEMBER_SUCCESS',
    UPDATE_MEMBER_FAILURE: 'UPDATE_MEMBER_FAILURE',

    RESEND_INVITATION_MEMBER_REQUEST: 'RESEND_INVITATION_MEMBER_REQUEST',
    RESEND_INVITATION_MEMBER_SUCCESS: 'RESEND_INVITATION_MEMBER_SUCCESS',
    RESEND_INVITATION_MEMBER_FAILURE: 'RESEND_INVITATION_MEMBER_FAILURE',

    RE_ACTIVATE_MEMBER_REQUEST: 'RE_ACTIVATE_MEMBER_REQUEST',
    RE_ACTIVATE_MEMBER_SUCCESS: 'RE_ACTIVATE_MEMBER_SUCCESS',
    RE_ACTIVATE_MEMBER_FAILURE: 'RE_ACTIVATE_MEMBER_FAILURE',

    CHANGE_MEMBER_ROLE_REQUEST: 'CHANGE_MEMBER_ROLE_REQUEST',
    CHANGE_MEMBER_ROLE_SUCCESS: 'CHANGE_MEMBER_ROLE_SUCCESS',
    CHANGE_MEMBER_ROLE_FAILURE: 'CHANGE_MEMBER_ROLE_FAILURE',

    SUSPEND_MEMBER_REQUEST: 'SUSPEND_MEMBER_REQUEST',
    SUSPEND_MEMBER_SUCCESS: 'SUSPEND_MEMBER_SUCCESS',
    SUSPEND_MEMBER_FAILURE: 'SUSPEND_MEMBER_FAILURE',

    SET_INVITE_PASSWORD_REQUEST: 'SET_INVITE_PASSWORD_REQUEST',
    SET_INVITE_PASSWORD_SUCCESS: 'SET_INVITE_PASSWORD_SUCCESS',
    SET_INVITE_PASSWORD_FAILURE: 'SET_INVITE_PASSWORD_FAILURE',

    // EEP - Education

    EDUCATION_VERIFICATION_REQUEST: 'EDUCATION_VERIFICATION_REQUEST',
    EDUCATION_VERIFICATION_SUCCESS: 'EDUCATION_VERIFICATION_SUCCESS',
    EDUCATION_VERIFICATION_FAILURE: 'EDUCATION_VERIFICATION__FAILURE',

    EDUCATION_VERIFICATION_RESULT_REQUEST: 'EDUCATION_VERIFICATION_RESULT_REQUEST',
    EDUCATION_VERIFICATION_RESULT_SUCCESS: 'EDUCATION_VERIFICATION_RESULT_SUCCESS',
    EDUCATION_VERIFICATION_RESULT_FAILURE: 'EDUCATION_VERIFICATION_RESULT_FAILURE',

    // EEP - Employment

    EMPLOYMENT_VERIFICATION_REQUEST: 'EMPLOYMENT_VERIFICATION_REQUEST',
    EMPLOYMENT_VERIFICATION_SUCCESS: 'EMPLOYMENT_VERIFICATION_SUCCESS',
    EMPLOYMENT_VERIFICATION_FAILURE: 'EMPLOYMENT_VERIFICATION_FAILURE',

    EMPLOYMENT_VERIFICATION_RESULT_REQUEST: 'EMPLOYMENT_VERIFICATION_RESULT_REQUEST',
    EMPLOYMENT_VERIFICATION_RESULT_SUCCESS: 'EMPLOYMENT_VERIFICATION_RESULT_SUCCESS',
    EMPLOYMENT_VERIFICATION_RESULT_FAILURE: 'EMPLOYMENT_VERIFICATION_RESULT_FAILURE',

    // EEP - Professional License

    PROFESSIONAL_LICENSE_VERIFICATION_REQUEST: 'PROFESSIONAL_LICENSE_VERIFICATION_REQUEST',
    PROFESSIONAL_LICENSE_VERIFICATION_SUCCESS: 'PROFESSIONAL_LICENSE_VERIFICATION_SUCCESS',
    PROFESSIONAL_LICENSE_VERIFICATION_FAILURE: 'PROFESSIONAL_LICENSE_VERIFICATION_FAILURE',

    PROFESSIONAL_LICENSE_VERIFICATION_RESULT_REQUEST:
        'PROFESSIONAL_LICENSE_VERIFICATION_RESULT_REQUEST',
    PROFESSIONAL_LICENSE_VERIFICATION_RESULT_SUCCESS:
        'PROFESSIONAL_LICENSE_VERIFICATION_RESULT_SUCCESS',
    PROFESSIONAL_LICENSE_VERIFICATION_RESULT_FAILURE:
        'PROFESSIONAL_LICENSE_VERIFICATION_RESULT_FAILURE',

    CLEAR_EEP_RESULT_DATA: 'CLEAR_EEP_RESULT_DATA',

    // LVA
    LOGS_VERIFICATION_REQUEST: 'LOGS_VERIFICATION_REQUEST',
    LOGS_VERIFICATION_SUCCESS: 'LOGS_VERIFICATION_SUCCESS',
    LOGS_VERIFICATION_FAILURE: 'LOGS_VERIFICATION_FAILURE',

    // Graph API
    GET_GRAPH_STATISTICS_REQUEST: 'GET_GRAPH_STATISTICS_REQUEST',
    GET_GRAPH_STATISTICS_SUCCESS: 'GET_GRAPH_STATISTICS_SUCCESS',
    GET_GRAPH_STATISTICS_FAILURE: 'GET_GRAPH_STATISTICS_FAILURE',

    //user pdf report
    INITIATE_USER_PDF_REPORT_REQUEST: 'INITIATE_USER_PDF_REPORT_REQUEST',
    INITIATE_USER_PDF_REPORT_SUCCESS: 'INITIATE_USER_PDF_REPORT_SUCCESS',
    INITIATE_USER_PDF_REPORT_FAILURE: 'INITIATE_USER_PDF_REPORT_FAILURE',

    UPDATE_GENERATED_PDF_REPORTS: 'UPDATE_GENERATED_PDF_REPORTS',

    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',

    //MVR
    FETCH_MVR_SUCCESS: 'FETCH_MVR_SUCCESS',
    FETCH_MVR_FAILURE: 'FETCH_MVR_FAILURE',
    FETCH_MVR_IN_PROGRESS: 'FETCH_MVR_IN_PROGRESS',
    UPDATE_MVR_ATTEMPT_DETAILS: 'UPDATE_MVR_ATTEMPT_DETAILS',
    INITIATE_MVR_SUCCESS: 'INITIATE_MVR_SUCCESS',
    INITIATE_MVR_FAILURE: 'INITIATE_MVR_FAILURE',
    INITIATE_MVR_IN_PROGRESS: 'INITIATE_MVR_IN_PROGRESS',

    MODIFY_USER_PROFILE_ADMIN_ENGAGEMENT: 'MODIFY_USER_PROFILE_ADMIN_ENGAGEMENT',

    TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
    SHOW_NAVIGATION_PREVIEW_MODAL: 'SHOW_NAVIGATION_PREVIEW_MODAL',

    RUN_USER_RISK_SCORE_SUCCESS: 'RUN_USER_RISK_SCORE_SUCCESS',
    RUN_USER_RISK_SCORE_FAILURE: 'RUN_USER_RISK_SCORE_FAILURE',
    RUN_USER_RISK_SCORE_REQUEST: 'RUN_USER_RISK_SCORE_REQUEST',

    RUN_USER_RISK_INSIGHTS_SUCCESS: 'RUN_USER_RISK_INSIGHTS_SUCCESS',
    RUN_USER_RISK_INSIGHTS_FAILURE: 'RUN_USER_RISK_INSIGHTS_FAILURE',
    RUN_USER_RISK_INSIGHTS_REQUEST: 'RUN_USER_RISK_INSIGHTS_REQUEST',

    FETCH_RISK_SCORE_AND_INSIGHT_SUCCESS: 'FETCH_RISK_SCORE_AND_INSIGHT_SUCCESS',
    FETCH_RISK_SCORE_AND_INSIGHT_FAILURE: 'FETCH_RISK_SCORE_AND_INSIGHT_FAILURE',
    FETCH_RISK_SCORE_AND_INSIGHT_REQUEST: 'FETCH_RISK_SCORE_AND_INSIGHT_REQUEST',

    CLEAR_USER_RISK_SCORE_AND_INSIGHT: 'CLEAR_RISK_SCORE_AND_INSIGHT',

    USER_PROFILE_ACTIVE_MODAL_TYPE: 'USER_PROFILE_ACTIVE_MODAL_TYPE',
    CLEAR_USER_PROFILE_ACTIVE_MODAL_TYPE: 'CLEAR_USER_PROFILE_ACTIVE_MODAL_TYPE',

    CACHE_USERS_GEOCODE_ADDRESS: 'CACHE_USERS_GEOCODE_ADDRESS',

    FETCH_USER_METADATA_SUCCESS: 'FETCH_USER_METADATA_SUCCESS',
    FETCH_USER_METADATA_REQUEST: 'FETCH_USER_METADATA_REQUEST',
    FETCH_USER_METADATA_FAILURE: 'FETCH_USER_METADATA_FAILURE',

    CLEAR_USER_METADATA: 'CLEAR_USER_METADATA',

    SHOW_SOC_WARNING_EVERY_TIME: 'SHOW_SOC_WARNING_EVERY_TIME',
    SHOW_GWC_WARNING_EVERY_TIME: 'SHOW_GWC_WARNING_EVERY_TIME',
};

export default actionConstants;
