import React, { useState } from 'react';
import 'react-phone-input-2/lib/material.css';

import { Tabs, Tab, Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import TabPanel from 'components/TabPanel';
import CustomInput from 'components/ThemeEditor/DesktopThemeEditor/components/CustomInput';
import { useCommonScreesStyles } from 'components/ThemeEditor/DesktopThemeEditor/components/MedallionPreview/styles';
import MedallionStepperHeader from 'components/ThemeEditor/DesktopThemeEditor/components/MedallionStepperHeader';
import CustomPhoneInput from 'components/ThemeEditor/DesktopThemeEditor/components/PhoneInput';
import TabContent from 'components/ThemeEditor/DesktopThemeEditor/components/TabContent';
import VerificationStep from 'components/ThemeEditor/DesktopThemeEditor/components/VerificationStep';
import PropTypes from 'prop-types';

import { useContinueVerificationScreenStyle } from './styles';

import { ReactComponent as QrCode } from 'asset/icons/qr_code.svg';

const ContinueVerificationScreen = ({ customScreenStyle, themeLogo, theme }) => {
    const classes = useContinueVerificationScreenStyle();
    const styles = useCommonScreesStyles();
    const [value, setValue] = useState(0);

    const handleChange = (_, newValue) => setValue(newValue);

    const mockData =
        'https://verify.authenticate.com/mobile?token=a3e64a66-22bb-4547-bdb2-9082a970b72d';

    // Store tab data in an array and map over it
    const tabData = [
        {
            label: 'SMS',
            title: 'Receive a link on your phone',
            subtitle:
                'Enter your mobile phone number and we will send you a link to complete this verification on your mobile device.',
            content: <CustomPhoneInput variant={theme.textInputVariant} />,
            showButton: true,
            forgetLink: 'Don’t have a valid ID or Passport and a device?',
        },
        {
            label: 'Email',
            title: 'Receive a link via email',
            subtitle:
                'Enter your email address and we will send you a link to complete this verification on a mobile device.',
            content: <CustomInput variant={theme.textInputVariant} />,
            showButton: true,
        },
        {
            label: 'QR Code',
            title: 'Scan QR Code',
            subtitle: "Point your phone's camera to this QR code and follow the link.",
            content: (
                <Box className={classes.qrcode}>
                    <QrCode />
                </Box>
            ),
            showButton: false,
        },
        {
            label: 'Link',
            title: 'Copy Link',
            subtitle: 'Copy and open this link on your phone.',
            content: <Typography className={classes.copylink}>{mockData}</Typography>,
            showButton: true,
        },
    ];

    return (
        <Box className={clsx(customScreenStyle, styles.cardWrapper)}>
            <MedallionStepperHeader themeLogo={themeLogo} activeStep={1} />
            <VerificationStep title="Continue your verification" />

            <Box className={classes.tabsWrapper}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    className={classes.tabs}
                    classes={{ indicator: classes.indicator }}
                >
                    {tabData.map((tab) => (
                        <Tab
                            key={tab.label}
                            label={tab.label}
                            className={classes.tabLabel}
                            classes={{ selected: classes.selected }}
                        />
                    ))}
                </Tabs>

                {tabData.map((tab, index) => (
                    <TabPanel key={tab.label + index} value={value} index={index}>
                        <TabContent
                            title={tab.title}
                            subtitle={tab.subtitle}
                            content={tab.content}
                            showButton={tab.showButton}
                            theme={theme}
                            forgetLink={tab?.forgetLink ?? null}
                        />
                    </TabPanel>
                ))}
            </Box>
        </Box>
    );
};

ContinueVerificationScreen.propTypes = {
    themeLogo: PropTypes.any,
    theme: PropTypes.object,
    customScreenStyle: PropTypes.string,
};

export default ContinueVerificationScreen;
