import React from 'react';

import { ButtonBase, Card, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useVerificationOptionScreenStyle } from './styles';

function VerificationCard({ Logo, heading, subHeading, theme, isHovered, ...rest }) {
    const classes = useVerificationOptionScreenStyle();

    return (
        <Card
            className={classes.issueIdCard}
            style={{ border: isHovered ? `2px solid ${theme.secondaryColor}` : null }}
        >
            <ButtonBase className={classes.cardBtn}>
                <Box className={classes.issueid}>
                    <Logo className={classes.logo} />
                </Box>
                <Typography className={classes.verificationCardHeading}>{heading}</Typography>
                <Typography className={classes.verificationCardSubHeading}>{subHeading}</Typography>
                <Typography className={classes.attempt}>2/2 attempts left</Typography>
            </ButtonBase>
        </Card>
    );
}

VerificationCard.propTypes = {
    isHovered: PropTypes.bool,
    Logo: PropTypes.any,
    theme: PropTypes.object,
    heading: PropTypes.string,
    subHeading: PropTypes.string,
};

export default VerificationCard;
