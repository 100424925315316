import { makeStyles } from '@material-ui/core';

export const usePhoneInputStyles = makeStyles((theme) => ({
    phoneInputContainer: {
        width: '100%',
        boxSizing: 'border-box',
        fontSize: '9.69px !important',
        height: '24px',
    },
    phoneInput: {
        height: '100%',
        width: '100% !important',
        padding: '6px 12px 5px 58px !important',
        border: `1px solid #E5E7EB !important`,
        fontFamily: `${theme.fontFamily}`,
        borderRadius: '0px',
        fontSize: '9.69px !important',
        overflow: 'hidden',

        '&:focus': {
            border: '1px solid #0B9AD8 !important',
            boxShadow: '0px 0px 4px rgba(27, 161, 219, 0.5) !important',
        },
    },

    filled: {
        background: '#E5E7EB !important',
    },
    phoneInputButton: {
        boxSizing: 'border-box',
        backgroundColor: '#F9FAFB',
        border: `1px solid #dddddd !important`,
        borderRadius: '6px 0 0 6px !important',
    },
    countryDropDown: {
        width: '220px !important',
        boxShadow: '1px 2px 5px rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',

        '& .country': {
            padding: '0px',
            display: 'flex',
            alignItems: 'center',

            '& .flag': {
                top: '0px',
                marginTop: '0px',
            },

            '& .country-name': {
                fontSize: '9.69px',
                fontFamily: `${theme.fontFamily}`,
            },

            '& .dial-code': {
                fontSize: '9.69px',
                fontFamily: `${theme.fontFamily}`,
            },
        },
    },
}));
