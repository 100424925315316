import apiEndpoints from 'api/endpoints';
import { DEFAULT_WORKFLOW_PAGE_SIZE } from 'views/V2/Workflow/constants';

import { globalAPI } from './globalApi';

//note: manage it better by creating one base api
export const workflowApi = globalAPI.injectEndpoints({
    endpoints: (builder) => ({
        fetchAllWorkflows: builder.query({
            query: (page = 0, size = DEFAULT_WORKFLOW_PAGE_SIZE) => ({
                url: `${apiEndpoints.workflowUrl}?pageNumber=${page}&pageSize=${size}`,
                method: 'GET',
            }),
        }),
        createWorkflow: builder.mutation({
            query: (payload) => ({
                url: `${apiEndpoints.workflowUrl}`,
                method: 'POST',
                body: payload,
            }),
        }),
        makeWorkflowDefault: builder.mutation({
            query: ({ workflowId }) => ({
                url: `${apiEndpoints.workflowUrl}/${workflowId}/default`,
                method: 'PUT',
            }),
            invalidatesTags: ['workflow'],
        }),
        deleteWorkflow: builder.mutation({
            query: ({ workflowId }) => ({
                url: `${apiEndpoints.workflowUrl}/${workflowId}`,
                method: 'DELETE',
            }),
        }),
        updateThemeLogo: builder.mutation({
            query: ({ id, data }) => ({
                url: `${apiEndpoints.workflowUrl}/${id}/logo`,
                method: 'PUT',
                body: data,
            }),
        }),
        fetchSingleWorkflow: builder.query({
            query: (nanoid) => ({
                url: `${apiEndpoints.workflowUrl}/${nanoid}`,
                method: 'GET',
            }),
        }),
        editWorkflow: builder.mutation({
            query: ({ nanoid, data }) => ({
                url: `${apiEndpoints.workflowUrl}/${nanoid}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['workflow'],
        }),
    }),
});

export const {
    useLazyFetchAllWorkflowsQuery: useFetchAllWorkflows,
    useCreateWorkflowMutation,
    useMakeWorkflowDefaultMutation,
    useDeleteWorkflowMutation,
    useUpdateThemeLogoMutation,
    useLazyFetchSingleWorkflowQuery,
    useEditWorkflowMutation,
} = workflowApi;

export default workflowApi.middleware;
