import React from 'react';

import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useContinueVerificationScreenStyle } from '../MedallionPreview/MedallionScreens/ContinueVerificationScreen/styles';

import MedallionThemeButton from '../MedallionThemeButton';

const TabContent = ({ title, subtitle, content, showButton, forgetLink, theme }) => {
    const classes = useContinueVerificationScreenStyle();
    return (
        <>
            <Box className={classes.mainSection}>
                <Typography className={classes.mainSectionHeading}>{title}</Typography>
                <Typography className={classes.mainSectionSubHeading}>{subtitle}</Typography>
                {content}
            </Box>
            {showButton && (
                <MedallionThemeButton button={theme.button}>Send Link</MedallionThemeButton>
            )}
            <Typography className={classes.linkText}>{forgetLink}</Typography>
        </>
    );
};

TabContent.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    content: PropTypes.element,
    showButton: PropTypes.bool,
    forgetLink: PropTypes.string,
    theme: PropTypes.object,
};

export default TabContent;
