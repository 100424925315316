import apiEndpoints from 'api/endpoints';

import { baseAPI } from './baseApi';

export const userProfileAPI = baseAPI.injectEndpoints({
    endpoints: (builder) => ({
        fetchAllSOCRecords: builder.mutation({
            query: (payload) => ({
                url: `${apiEndpoints.getSOCRecords}`,
                method: 'POST',
                body: payload,
            }),
        }),

        fetchAllGWCRecords: builder.query({
            query: ({ uuId }) => ({
                url: `${apiEndpoints.getGWCRecords}/${uuId}/fetch`,
                method: 'GET',
            }),
        }),

        initiateSOCApi: builder.mutation({
            query: (payload) => ({
                url: `${apiEndpoints.initiateSOC}`,
                method: 'POST',
                body: payload,
            }),
        }),

        initiateGWCApi: builder.mutation({
            query: ({ uuId }) => ({
                url: `${apiEndpoints.initiateGWC}/${uuId}`,
                method: 'POST',
            }),
        }),
    }),
});

export const {
    useFetchAllSOCRecordsMutation: useFetchSOCRecords,
    useLazyFetchAllGWCRecordsQuery: useFetchGWCRecords,
    useInitiateGWCApiMutation: useInitiateGWC,
    useInitiateSOCApiMutation: useInitiateSOC,
} = userProfileAPI;

export default userProfileAPI.middleware;
