import React from 'react';

import { Checkbox, Typography, Box } from '@material-ui/core';
import clsx from 'clsx';
import MedallionHeader from 'components/ThemeEditor/DesktopThemeEditor/components/MedallionHeader';
import { useCommonScreesStyles } from 'components/ThemeEditor/DesktopThemeEditor/components/MedallionPreview/styles';
import MedallionThemeButton from 'components/ThemeEditor/DesktopThemeEditor/components/MedallionThemeButton';
import { IDENTITY_SCREEN_INFO_MESSAGES } from 'components/ThemeEditor/constants';
import PropTypes from 'prop-types';

import { useVerifyIdentityStyles } from './styles';

import { ReactComponent as AuthenticateLogo } from 'asset/img/Authenticate_R_logo_white.svg';

function VerifyIdentityScreen({ themeLogo, customScreenStyle, ...props }) {
    const classes = useVerifyIdentityStyles();
    const styles = useCommonScreesStyles();

    return (
        <Box className={clsx(customScreenStyle, styles.cardWrapper)}>
            <MedallionHeader
                themeLogo={themeLogo}
                title="Identity Authentication & Background Checks"
            />
            <Box className={classes.infoBox}>
                {IDENTITY_SCREEN_INFO_MESSAGES.map((data) => (
                    <Box className={classes.contentWrapper} key={data.id}>
                        <Box className={classes.rowContainer}>
                            <data.icon className={classes.rowIcon} />
                            <Box className={classes.rowTxt}>{data.text}</Box>
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box className={clsx(classes.contentWrapper, classes.terms)}>
                <Checkbox
                    classes={{
                        checked: classes.checked,
                    }}
                    size="small"
                    className={classes.rowCheckbox}
                />

                <Typography className={classes.rowTxt}>
                    I agree to{' '}
                    <a
                        href="https://authenticate.com/"
                        className={classes.link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Authenticate
                    </a>
                    's{' '}
                    <a
                        href="https://authenticate.com/terms-of-use/"
                        className={classes.link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Terms of Use
                    </a>{' '}
                    and{' '}
                    <a
                        href="https://authenticate.com/privacy-policy/"
                        className={classes.link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Privacy Policy.
                    </a>
                </Typography>
            </Box>
            <MedallionThemeButton button={props.button}>
                <AuthenticateLogo
                    className={clsx(
                        classes.authenticateLogo,
                        props.button?.variant === 'outlined'
                            ? classes.authenticateLogoOutlined
                            : null
                    )}
                />{' '}
                uthenticate
            </MedallionThemeButton>
            {/* <Button
                    variant={props.button?.variant || 'contained'}
                    classes={{
                        contained: styles.contained,
                        outlined: styles.outlined,
                    }}
                >
                   
                </Button> */}
            {/* </Box> */}
        </Box>
    );
}

VerifyIdentityScreen.propTypes = {
    title: PropTypes.string,
    themeLogo: PropTypes.any,
    button: PropTypes.object,
    customScreenStyle: PropTypes.string,
};

export default VerifyIdentityScreen;
