import React from 'react';

import { Box } from '@material-ui/core';

import { useCustomStepperStyles } from '../../styles';

const ActiveStep = () => {
    const classes = useCustomStepperStyles();

    return (
        <Box className={classes.activeStep}>
            <Box component={'span'} />
        </Box>
    );
};

export default ActiveStep;
