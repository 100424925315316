import React from 'react';

import { ReactComponent as Clock } from 'asset/icons/theme_clock.svg';
import { ReactComponent as Lock } from 'asset/icons/theme_lock.svg';
import { ReactComponent as Shield } from 'asset/icons/theme_shield.svg';

export const IDENTITY_SCREEN_INFO_MESSAGES = [
    {
        id: 1,
        icon: Lock,
        text: 'We use machine learning, computer vision, and AI for a forensic identity authentication.',
    },
    {
        id: 2,
        icon: Shield,
        text: (
            <>
                Your information will only be used by{' '}
                <a href="https://authenticate.com/" target="_blank" rel="noreferrer">
                    Authenticate.com
                </a>{' '}
                for verification purposes.
            </>
        ),
    },
    {
        id: 3,
        icon: Clock,
        text: 'This process takes only a couple of minutes.',
    },
];

export const TWO_FA_STEPS = ['2FA', 'Identity'];

export const MOBILE_MENU_OPTIONS = ['Preview', 'Theme Editor'];
