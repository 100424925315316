import React from 'react';

import { Box, IconButton, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useVerificationStepStyles } from './styles';

import { ReactComponent as BackIcon } from 'asset/icons/back_icon.svg';

const VerificationStep = ({ title }) => {
    const classes = useVerificationStepStyles();

    return (
        <Box className={classes.verificationStepContainer}>
            <IconButton className={classes.backButton}>
                <BackIcon className={classes.backIcon} />
            </IconButton>
            <Typography className={classes.title}>{title}</Typography>
        </Box>
    );
};

VerificationStep.propTypes = {
    title: PropTypes.string,
};

export default VerificationStep;
