import { makeStyles } from '@material-ui/core';

export const useVerificationStepStyles = makeStyles((theme) => ({
    verificationStepContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '11.08px',
        padding: '7.62px 11.08px',
        backgroundColor: `${theme.headerColor}`,
    },
    backButton: {
        padding: '4px',
    },
    backIcon: {
        height: '16.62px',
        width: '16.62px',
        color: '#1F2937',
    },
    title: {
        fontFamily: `${theme.fontFamily}`,
        color: '#333333',
        fontWeight: 500,
        fontSize: '9.69px',
        lineHeight: '11.49px',
    },
}));
