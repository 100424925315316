import { makeStyles } from '@material-ui/core/styles';

export const useVerificationOptionScreenStyle = makeStyles((theme) => ({
    issueIdCard: {
        border: '1px solid #EAECF0',
        borderRadius: '5px',
        boxShadow: '0px 1px 10px 0px #0000001A',

        '&:hover': {
            border: `1px solid ${theme.secondaryColor}`,
        },
    },
    cardContainer: {
        marginTop: '13.98px',
    },
    verificationOption: {
        fontFamily: `${theme.fontFamily}`,
        color: '#666666',
        fontWeight: 400,
        textTransform: 'uppercase',
        margin: '12px 0px',
        fontSize: '10px',
        textAlign: 'center',
    },
    cardBtn: {
        width: '100%',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
    },
    issueid: {
        height: '32px',
        width: '32px',
        backgroundColor: '#F2F4F7',
        borderRadius: '5px',
        display: 'grid',
        placeItems: 'center',
    },
    logo: {
        height: '20px',
        width: '20px',
    },
    verificationCardHeading: {
        fontFamily: theme.fontFamily,
        fontWeight: '500',
        fontSize: '10px',
        lineHeight: '11.85px',
        color: '#101828',
    },
    verificationCardSubHeading: {
        fontFamily: theme.fontFamily,
        fontSize: '10px',
        lineHeight: '14px',
        color: '#475467',
        textAlign: 'left',
    },
    attempt: {
        fontFamily: theme.fontFamily,
        fontWeight: '400',
        fontSize: '8px',
        lineHeight: '9.84px',
        color: '#9CA3AF',
    },
}));
