import { useEffect } from 'react';

import clsx from 'clsx';
import DropdownSelect from 'components/DropdownSelect';
import PropTypes from 'prop-types';

import classes from './dropdown.module.css';

function Dropdown(props) {
    const { name, value, onChange, options = [{}], title } = props;

    const handleChange = (name, value) => {
        onChange?.({ name, value });
    };

    useEffect(() => {
        if (!value) {
            onChange?.({ name, value: options[0].value });
        }
    });

    return (
        <DropdownSelect
            name={name}
            value={value}
            label={title}
            options={options}
            customContainerStyle={classes.dropdown}
            customLabelStyle={classes.title}
            customStyleClass={clsx(
                classes.dropdownContainer,
                title === 'Font Style' && classes.customWidth
            )}
            menuPropsOverride={{
                PaperProps: {
                    className: classes.menuStyles,
                },
            }}
            onChange={handleChange}
        />
    );
}

Dropdown.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    title: PropTypes.string,
};

export default Dropdown;
