import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { PAGE_SIZE } from 'helper/values';
import useDebounce from 'hooks/useDebounce';

import { useGetBrandListingsQuery } from 'redux/slices/globalApi';

import { PROPERTY_RENTALS } from '../constants';

const useBrandListings = (brandUuid) => {
    const { integrationName } = useParams();

    const [listings, setListings] = useState([]);
    const [page, setPage] = useState(1);

    const [searchTerm, setSearchTerm] = useState('');

    const debouncedSearchTerm = useDebounce(searchTerm, 400);

    const [pollingInterval, setPollingInterval] = useState(0);

    // Normal query with polling
    const { data, isFetching } = useGetBrandListingsQuery(
        { integrationName, brandUuid, page, filter: debouncedSearchTerm },
        {
            refetchOnMountOrArgChange: true,
            pollingInterval: pollingInterval, // Poll every minute
        }
    );

    const listingRef = useRef(null);

    useEffect(() => {
        const disableConditionHostfully =
            integrationName === PROPERTY_RENTALS.HOSTFULLY && page > 1;
        const disableConditionDefault =
            integrationName !== PROPERTY_RENTALS.HOSTFULLY && data?.listings?.length > 0;
        if (disableConditionHostfully || disableConditionDefault) {
            setPollingInterval(0); // Disable polling after first page
        }

        const listingCountCondition =
            integrationName === PROPERTY_RENTALS.HOSTFULLY
                ? data?.listings?.length < PAGE_SIZE
                : data?.listings?.length === 0;
        if (page === 1 && listingCountCondition) {
            setPollingInterval(60000); // Enable polling for the first page
        }
        if (data) {
            setListings((prevListings) =>
                page === 1 ? data.listings : [...prevListings, ...data.listings]
            );
        }
    }, [data, page, integrationName]);

    const handleScroll = () => {
        const clientHeight = listingRef?.current?.clientHeight;
        const scrollTop = listingRef?.current?.scrollTop;
        const scrollHeight = listingRef?.current?.scrollHeight;

        const reachedEnd = clientHeight + scrollTop >= scrollHeight - 5;

        if (reachedEnd && !isFetching && data?.listings?.length > 0) {
            setPage((prev) => prev + 1);
        }
    };

    const onChange = (e) => {
        setSearchTerm((prevValue) => {
            //this is to reset the page if it was changed on scroll
            if (!prevValue || prevValue !== e.target.value) {
                setPage(1);
            }
            return e.target.value;
        });
    };

    return {
        searchTerm,
        listingRef,
        listings,
        handleScroll,
        onChange,
        totalCount: data?.total,
        isFetching,
    };
};

export default useBrandListings;
