import React from 'react';

import { Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ErrorOutline } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { styles } from './style';

function InputHelperText({
    classes,
    maxCharLimit,
    isErrIconResponsive,
    calcCharCount,
    helperText,
    showCharLimit,
    hideErrIconOnDesktop,
}) {
    const hideOnMobileClass = isErrIconResponsive
        ? clsx(classes.errorIcon, classes.hideErrorIcon)
        : classes.errorIcon;

    const errClassName = hideErrIconOnDesktop ? classes.hideErrIconOnDesktop : hideOnMobileClass;
    return (
        <Box className={classes.helperTextWrapper}>
            <span className={classes.errorMessage}>
                <ErrorOutline className={errClassName} />
                <Typography className={classes.helperText}>{helperText}</Typography>
            </span>
            {showCharLimit && calcCharCount >= maxCharLimit && (
                <Typography className={clsx(classes.helperText, classes.charErr)}>
                    {maxCharLimit}/{maxCharLimit}
                </Typography>
            )}
        </Box>
    );
}

InputHelperText.propTypes = {
    helperText: PropTypes.string,
    classes: PropTypes.object,
    showCharLimit: PropTypes.bool,
    maxCharLimit: PropTypes.number,
    calcCharCount: PropTypes.number,
    isErrIconResponsive: PropTypes.bool,
    hideErrIconOnDesktop: PropTypes.bool,
};

export default withStyles(styles)(InputHelperText);
