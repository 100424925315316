import React from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useCommonScreesStyles } from 'components/ThemeEditor/DesktopThemeEditor/components/MedallionPreview/styles';
import MedallionStepperHeader from 'components/ThemeEditor/DesktopThemeEditor/components/MedallionStepperHeader';
import VerificationStep from 'components/ThemeEditor/DesktopThemeEditor/components/VerificationStep';
import PropTypes from 'prop-types';

import { useVerificationOptionScreenStyle } from './styles';

import { ReactComponent as IssuedId } from 'asset/icons/verification_id.svg';
import { ReactComponent as Quiz } from 'asset/icons/verification_quiz.svg';

import VerificationCard from './VerificationCard';

const VerificationOptionsScreen = ({
    customScreenStyle,
    themeLogo,
    theme,
    isHovered,
    ...props
}) => {
    const styles = useCommonScreesStyles();
    const classes = useVerificationOptionScreenStyle();

    return (
        <Box className={clsx(customScreenStyle, styles.cardWrapper)}>
            <MedallionStepperHeader themeLogo={themeLogo} activeStep={1} />
            <VerificationStep title="Identity Verification" />
            <Box className={styles.mainContent}>
                <Typography className={styles.desc}>
                    You will need to verify your identity using <strong>both</strong> methods shown
                    below
                </Typography>
                <Box className={classes.cardContainer}>
                    <VerificationCard
                        theme={theme}
                        isHovered={isHovered}
                        Logo={IssuedId}
                        heading="Passport or Government-Issued ID"
                        subHeading="Successfully verified using photo ID. Please authenticate yourself using KBA."
                    />
                    <Typography className={classes.verificationOption}>AND</Typography>
                    <VerificationCard
                        theme={theme}
                        isHovered={isHovered}
                        Logo={Quiz}
                        heading="Knowledge Authentication Quiz"
                        subHeading="This option is only available to persons
                    found in US commercial databases."
                    />
                </Box>
            </Box>
        </Box>
    );
};

VerificationOptionsScreen.propTypes = {
    isHovered: PropTypes.bool,
    themeLogo: PropTypes.any,
    theme: PropTypes.object,
    customScreenStyle: PropTypes.string,
};

export default VerificationOptionsScreen;
