import React from 'react';

import { Box } from '@material-ui/core';
import { useCommonScreesStyles } from 'components/ThemeEditor/DesktopThemeEditor/components/MedallionPreview/styles';
import PropTypes from 'prop-types';

import LogoHeader from '../LogoHeader';

function MedallionHeader({ themeLogo, title }) {
    const classes = useCommonScreesStyles();

    return (
        <>
            <LogoHeader themeLogo={themeLogo} />
            <Box className={classes.screenHeading}>{title}</Box>
        </>
    );
}

MedallionHeader.propTypes = {
    title: PropTypes.string,
    themeLogo: PropTypes.object,
};

export default MedallionHeader;
