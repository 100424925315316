const API = {
    BASE_URL: 'https://api-v3-alpha.authenticating.com',
};

const APPLICATION = {
    VERSION: process.env.REACT_APP_PORTAL_VERSION,
    URL: 'https://portal-alpha.authenticate.com',
};

const DEV_TOOLS = {
    logError: true,
    enableReduxDevTools: true,
};

const ANALYTICS = {
    GTM: {
        ID: '',
        ENABLED: false,
    },
    DATADOG: {
        RUM: {
            APPLICATION_ID: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID,
            CLIENT_TOKEN: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
            ENABLED: process.env.REACT_APP_DATADOG_RUM_ENABLED === 'true',
        },
    },
};

const PROPERTY_RENTALS = {
    HOSTFULLY: {
        CONNECT_URL:
            'https://sandbox.hostfully.com/api/auth/oauth/authorize?scope=FULL&grantType=REFRESH_TOKEN',
    },
};

module.exports = {
    ENV: 'beta',
    API,
    DEV_TOOLS,
    ANALYTICS,
    APPLICATION,
    PROPERTY_RENTALS,
};
