import { fieldDefaultValues } from 'components/ThemeEditor/menuConfig';

import actionConstants from 'redux/actions/actionConstant';

let initialState = {
    ...fieldDefaultValues,
    isSaved: true,
};

export default function themeEditor(state = initialState, action) {
    switch (action.type) {
        case actionConstants.SET_FIELD_VALUE: {
            return {
                ...state,
                [action.name]: action.value,
                isSaved: false,
            };
        }

        case actionConstants.FETCH_ONE_WORKFLOW_SUCCESS: {
            if (!action.payload?.workflow) {
                return { ...state, isSaved: true };
            }

            const savedTheme = action.payload?.verify_ui_config?.theme;
            return {
                ...state,
                ...savedTheme,
                isSaved: true,
            };
        }

        case actionConstants.FETCH_PREVIOUS_THEME_STATE: {
            return {
                ...state,
                ...action.payload,
                isSaved: true,
            };
        }

        case actionConstants.SAVE_THEME_VALUES: {
            initialState = state;

            return {
                ...state,
                isSaved: true,
            };
        }

        case actionConstants.RESET_FIELD_VALUE: {
            return {
                ...fieldDefaultValues,
                isSaved: true,
            };
        }

        case actionConstants.RESET_ALL_REDUCERS: {
            return {
                ...initialState,
                isSaved: true,
            };
        }
        default:
            return state;
    }
}
