import React from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import CustomInput from 'components/ThemeEditor/DesktopThemeEditor/components/CustomInput';
import MedallionStepperHeader from 'components/ThemeEditor/DesktopThemeEditor/components/MedallionStepperHeader';
import MedallionThemeButton from 'components/ThemeEditor/DesktopThemeEditor/components/MedallionThemeButton';
import CustomPhoneInput from 'components/ThemeEditor/DesktopThemeEditor/components/PhoneInput';
import VerificationStep from 'components/ThemeEditor/DesktopThemeEditor/components/VerificationStep';
import PropTypes from 'prop-types';

import { useCommonScreesStyles } from '../../styles';
import { useTwoFAScreenStyles } from './style';

const TwoFAScreen = ({ themeLogo, customScreenStyle, theme }) => {
    const classes = useTwoFAScreenStyles();
    const styles = useCommonScreesStyles();

    return (
        <Box className={clsx(customScreenStyle, styles.cardWrapper)}>
            <MedallionStepperHeader themeLogo={themeLogo} />
            <VerificationStep title="Start Verification" />
            <Box className={styles.mainContent}>
                <Typography className={styles.desc}>
                    Please confirm your e-mail & phone number below to proceed with Two Factor
                    Authentication (2FA).
                </Typography>
                <CustomInput variant={theme.textInputVariant} />
                <CustomPhoneInput variant={theme.textInputVariant} />
            </Box>
            <Box className={classes.buttonContainer}>
                <MedallionThemeButton button={theme.button}>Proceed</MedallionThemeButton>
            </Box>
        </Box>
    );
};
TwoFAScreen.propTypes = {
    themeLogo: PropTypes.object,
    theme: PropTypes.object,
    customScreenStyle: PropTypes.string,
};

export default TwoFAScreen;
