import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Box, Typography } from '@material-ui/core';
import Modal from 'components/ModalV2';
import Button from 'components/V2/Button';
import PropTypes from 'prop-types';

import { showNavigationPreview } from 'redux/actions/settings';

import styles from './previewNavbar.module.css';

import ClockIcon from 'asset/img/timer-icon.svg';

// NOTE: This component will be used to display information about new features in the application. currently displaying SOC & GWC feature

function PreviewNavbar({ showNavigationPreview, navigationPreview }) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!navigationPreview) {
            setOpen(true);
        }
    }, [navigationPreview, setOpen, showNavigationPreview]);

    const handleNext = () => {
        setOpen(false);
        showNavigationPreview();
    };

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            disableBackdropClick
            className={styles.previewNavbarModal}
        >
            <Box className={styles.previewNavbarContainer}>
                <Box className={styles.previewHeader}>
                    <img src={ClockIcon} alt="feature preview GIF" className={styles.timerIcon} />
                </Box>
                <Box className={styles.navigationBody}>
                    <Typography className={styles.cardHeading}>New Release 🎉</Typography>
                    <Typography className={styles.cardDisc}>
                        The user profile has been improved to enable{' '}
                        <strong>Sex Offender Check Pro</strong> and {''}
                        <strong>Global Watchlist Check</strong>
                        {''} to be run directly from it.🔥 To get started, go to a user's profile
                        and click on the <strong>Background Checks</strong> button.
                    </Typography>
                </Box>
            </Box>
            <Box className={styles.btnGroup}>
                <Button
                    intent="primary"
                    size="lg"
                    onClick={handleNext}
                    className={styles.previewBtn}
                >
                    Okay
                </Button>
            </Box>
        </Modal>
    );
}

PreviewNavbar.propTypes = {
    navigationPreview: PropTypes.bool,
    showNavigationPreview: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        navigationPreview: state.settings?.navigationPreview,
    };
}

export default connect(mapStateToProps, { showNavigationPreview })(PreviewNavbar);
