import React from 'react';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useCommonScreesStyles } from '../MedallionPreview/styles';

import Medallion from 'asset/img/Medallion_Logo.svg';

const LogoHeader = ({ themeLogo }) => {
    const classes = useCommonScreesStyles();
    const logoSrc = themeLogo?.logo ?? Medallion;

    return (
        <Box className={classes.logoWrapper}>
            <img src={logoSrc} alt="Company Logo" className={classes.headerLogo} />
        </Box>
    );
};
LogoHeader.propTypes = {
    themeLogo: PropTypes.object,
};

export default LogoHeader;
