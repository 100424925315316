import actionType from 'redux/actions/actionConstant';

const initialState = {
    fetchAllWorkflows: undefined,
    createWorkflow: undefined,
    updateWorkflow: undefined,
    fetchOneWorkflow: undefined,
    updateWorkflowThemeLogo: undefined,
    workflowSettings: {
        data: null,
        isSaved: false,
    },
    workflowTitleValues: undefined,
    workflowThemeLogo: undefined,
    workflowThemeValues: undefined,
    isThemeChanged: false,
    isLogoCleared: false,
};

export default function workflow(state = initialState, action) {
    switch (action.type) {
        case actionType.FETCH_ALL_WORKFLOWS_SUCCESS:
            return {
                ...state,
                fetchAllWorkflows: action.payload,
            };

        case actionType.CREATE_WORKFLOW_SUCCESS:
            return {
                ...state,
                createWorkflow: action.payload,
            };

        case actionType.UPDATE_WORKFLOW_SUCCESS:
            return {
                ...state,
                updateWorkflow: action.payload,
            };

        case actionType.FETCH_ONE_WORKFLOW_SUCCESS:
            return {
                ...state,
                fetchOneWorkflow: action.payload,
            };

        case actionType.UPDATE_WORKFLOW_THEME_LOGO_SUCCESS:
            return {
                ...state,
                updateWorkflowThemeLogo: action.payload,
            };

        case actionType.SET_SETTINGS_VALUES:
            return {
                ...state,
                workflowSettings: {
                    data: action.payload,
                    isSaved: false,
                },
            };

        case actionType.SAVE_THEME_VALUES: {
            return {
                ...state,
                workflowThemeValues: action.payload,
            };
        }

        case actionType.CLEAR_THEME_VALUES: {
            return {
                ...state,
                workflowThemeValues: undefined,
            };
        }

        case actionType.IS_THEME_CHANGED: {
            return {
                ...state,
                isThemeChanged: action.payload,
            };
        }

        case actionType.RESET_SETTINGS_VALUES:
            return {
                ...state,
                workflowSettings: {
                    data: null,
                    isSaved: false,
                },
            };

        case actionType.SET_WORKFLOW_TITLE_VALUES:
            return {
                ...state,
                workflowTitleValues: action.payload,
            };

        case actionType.CLEAR_WORKFLOW_TITLE_VALUES:
            return {
                ...state,
                workflowTitleValues: undefined,
            };

        case actionType.CLEAR_FETCH_ONE_WORKFLOW:
            return {
                ...state,
                fetchOneWorkflow: undefined,
            };

        case actionType.SET_WORKFLOW_THEME_LOGO:
            return {
                ...state,
                workflowThemeLogo: action.payload,
            };

        case actionType.CLEAR_WORKFLOW_THEME_LOGO:
            return {
                ...state,
                workflowThemeLogo: undefined,
            };

        case actionType.RESET_ALL_REDUCERS:
            return {
                ...initialState,
            };

        case actionType.RESET_THEME_AND_LOGO_VALUES:
            return {
                ...initialState,
                workflowThemeLogo: undefined,
                workflowThemeValues: undefined,
                isThemeChanged: false,
                isLogoCleared: false,
            };

        case actionType.IS_LOGO_CLEARED:
            return {
                ...state,
                isLogoCleared: true,
            };

        default:
            return state;
    }
}
