import React, { useEffect, useRef, useState } from 'react';
import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/css';

import { Box, Divider, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import './colorPicker.css';
import styles from './mobileColorChooser.module.css';

const MobileColorPicker = ({ name, value, onChange, defaultValue, title, setIsHovered }) => {
    const timerRef = useRef(null);

    const [showColorPicker, setShowColorPicker] = useState(false);
    const [color, setColor] = useColor(value);

    const timer = name === 'secondaryColor' ? 500 : 50;

    const handleClick = () => setShowColorPicker((prev) => !prev);

    const handleColorChange = (color) => {
        setColor(color);

        if (name === 'secondaryColor') {
            setIsHovered(true);
        }
        const { hex } = color;
        onChange({ name, value: hex });

        timerRef.current = setTimeout(() => {
            setIsHovered(false);
        }, timer);
    };

    useEffect(() => {
        if (!value) {
            onChange?.({ name, value: defaultValue });
        }
        // eslint-disable-next-line
    }, [value, name, defaultValue]);
    return (
        <>
            <Typography className={styles.title}>{title}</Typography>
            <Box className={styles.colorContainer}>
                <button type="button" onClick={handleClick} className={styles.colorPickerButton}>
                    <Box className={styles.colorDetails}>
                        <Typography className={styles.colorCode}>{color.hex}</Typography>
                        <Box
                            className={styles.colorPreview}
                            style={{ backgroundColor: color.hex }}
                        />
                    </Box>
                </button>
                {showColorPicker && (
                    <>
                        <Divider className={styles.divider} />
                        <ColorPicker color={color} onChange={handleColorChange} hideInput />
                    </>
                )}
            </Box>
        </>
    );
};

MobileColorPicker.propTypes = {
    name: PropTypes.string,
    value: PropTypes.object,
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
    title: PropTypes.string,
    setIsHovered: PropTypes.object,
};

export default MobileColorPicker;
