/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRutterLink } from 'react-rutter-link';

import { ButtonBase, Card, CardContent, Grid, Typography } from '@material-ui/core';
import ActiveBadge from 'components/ActiveBadge';
import { adminTypes, unauthorizedUserMessage } from 'helper/constant';
import { checkAdminAccess } from 'helper/utility';
import PropTypes from 'prop-types';

import { setActiveIntegrations } from 'redux/slices/integration';

import commonClasses from '../../index.module.css';
import classes from './integrationCards.module.css';

import { PROPERTY_RENTALS, integrationItems } from '../../constants';

const { COMPANY_OWNER, COMPANY_ADMIN } = adminTypes;

const IntegrationCards = ({
    history,
    match,
    active,
    settingGetAction,
    settingsValues,
    authorized,
    openToast,
    refreshCompanyAction,
    loggedInAdminRole,
    ...restProps
}) => {
    const dispatch = useDispatch();

    const [integrations, setIntegrations] = useState(integrationItems);

    useEffect(() => {
        if (!authorized) return;
        restProps.activeAction(
            { companyAccessCode: restProps.uid },
            { loader: true },
            restProps.token,
            (res) => {
                if (res.ok) dispatch(setActiveIntegrations(res.body));
            }
        );
        refreshCompanyAction({
            token: restProps.token,
            companyUUID: restProps.uid,
        });
    }, []);

    useEffect(() => {
        if (!authorized) return openToast({ variant: 'info', message: unauthorizedUserMessage });

        const eCommerceIntegration = active?.length
            ? active?.find((setting) => setting.name === 'E-Commerce')
            : false;

        // Temporarily hiding hostfully from integrations
        const updatedIntegrations = integrationItems
            .filter(
                (integration) =>
                    process.env.REACT_APP_BUILD_ENV !== 'production' ||
                    integration.name?.toLowerCase() !== PROPERTY_RENTALS.HOSTFULLY
            )
            .map((integration) => {
                let isActive = Boolean(
                    active?.find((item) => item.name === integration.name)?.active
                );

                integration['isActive'] = isActive ?? false;
                return integration;
            });

        setIntegrations(updatedIntegrations);

        if (eCommerceIntegration) {
            settingGetAction(
                {
                    companyAccessCode: restProps.uid,
                    integration_id: eCommerceIntegration.id,
                },
                { loader: true },
                restProps.token
            );
        }
    }, [active, authorized, refreshCompanyAction]);

    //E-commerce on success
    const onSuccess = (output) => {
        history.push({
            pathname: `${match.path}/e-commerce`,
            state: { publicKey: output },
        });
    };
    const rutterConfig = {
        publicKey: process.env.REACT_APP_RUTTER_KEY,
        onSuccess: onSuccess,
    };

    const { open } = useRutterLink(rutterConfig);

    const isItAllowedAdmin = checkAdminAccess(loggedInAdminRole, [COMPANY_OWNER, COMPANY_ADMIN]);

    const rutterLinkCondition = () =>
        settingsValues?.length ? history.push(`${match.path}/e-commerce`) : open();

    const openRutter = () => {
        isItAllowedAdmin ? rutterLinkCondition() : history.push(`${match.path}/e-commerce`);
    };

    const onCardClick = (key) => {
        if (!authorized) {
            openToast({
                variant: 'default',
                message: unauthorizedUserMessage,
            });
        } else if (key === 'eCommerce') {
            openRutter();
        } else {
            history.push(`${match.path}/${key}`);
        }
    };

    return (
        <>
            <Typography className={commonClasses.integrationText_1}>Integrations</Typography>
            <Grid container className={classes.container}>
                {integrations.map((item) => (
                    <Grid item key={item.key}>
                        <Card className={classes.card}>
                            <ButtonBase
                                onClick={() => onCardClick(item.key)}
                                className={classes.btnBase}
                            >
                                <CardContent className={classes.cardContent}>
                                    <Typography className={classes.cardLogo}>
                                        <img
                                            src={item.logo}
                                            alt={item.alt}
                                            className={classes.company_logo}
                                        />
                                    </Typography>
                                    <Typography
                                        className={classes.cardDetail}
                                        color="textSecondary"
                                        align="left"
                                    >
                                        <span>{item.description}</span>
                                    </Typography>
                                    {item.isActive ? (
                                        <ActiveBadge className={classes.activeBadge} />
                                    ) : null}
                                    <img
                                        className={classes.maskedLogo}
                                        src={item.maskedLogo}
                                        alt={item.alt}
                                    />
                                </CardContent>
                            </ButtonBase>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

IntegrationCards.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
    active: PropTypes.array,
    settingGetAction: PropTypes.func,
    settingsValues: PropTypes.array,
    authorized: PropTypes.bool,
    openToast: PropTypes.func,
    refreshCompanyAction: PropTypes.func,
    loggedInAdminRole: PropTypes.string,
};

export default IntegrationCards;
