import React from 'react';
import { connect } from 'react-redux';

import { Box, Typography } from '@material-ui/core';
import { adminRole } from 'helper/constant';
import PropTypes from 'prop-types';

function VerifyAccessLevel({
    loggedInAdminRole,
    returnType,
    className,
    content,
    children,
    allowedAdmins = [],
    company,
    hideContentWhenInActive = true, //hide contents when company in-active state
}) {
    const role = adminRole[loggedInAdminRole];

    const isCompanyInActive = !company?.authorized;

    if (hideContentWhenInActive && isCompanyInActive) return <></>;

    if (allowedAdmins.includes(role)) {
        return <>{children}</>;
    }

    switch (returnType) {
        case 'unClickable':
            return <Box className={className}>{content}</Box>;
        case 'credential':
            return <Typography className={className}>***********</Typography>;
        default:
            return <></>;
    }
}

VerifyAccessLevel.propTypes = {
    loggedInAdminRole: PropTypes.string,
    returnType: PropTypes.string,
    className: PropTypes.string,
    content: PropTypes.string,
    children: PropTypes.node,
    allowedAdmins: PropTypes.arrayOf(PropTypes.string),
    company: PropTypes.object,
    hideContentWhenInActive: PropTypes.bool,
};

function mapStateToProps(state) {
    return {
        loggedInAdminRole: state.auth?.userData.user.type,
        company: state.auth?.userData?.user?.company ?? {},
    };
}

export default connect(mapStateToProps, null)(VerifyAccessLevel);
