import React from 'react';

import { Box } from '@material-ui/core';

import { useCustomStepperStyles } from '../../styles';

const DefaultStep = () => {
    const classes = useCustomStepperStyles();
    return <Box className={classes.defaultStep} />;
};

export default DefaultStep;
