import React from 'react';

import CustomBaseEdge from 'components/ReactFlowChart/CustomBaseEdge';
import PropTypes from 'prop-types';

import styles from '../flowChart.module.css';

const OrEdge = ({ style, ...props }) => {
    const styleExtended = {
        stroke: '#12B76A',
        strokeWidth: 2,
        strokeDasharray: '5 2',
        ...style,
    };

    return (
        <CustomBaseEdge
            {...props}
            label="OR"
            labelClass={styles.orEdgeLabel}
            style={styleExtended}
        />
    );
};

OrEdge.propTypes = {
    style: PropTypes.object,
};

export default OrEdge;
